import { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Calendar.css';
import Icon from '../Icon/Icon';
import { useQuery } from 'react-query';
import { getAvailableDates } from '../../utils/api/capture';
import moment from 'moment';

function Calendar_Planifier({ setValue = () => {}, watch = () => {}, errors }) {
  const { data: availableDates } = useQuery('gestion', getAvailableDates);
  const [selectedDateCaptur, setSelectedDateCaptur] = useState();

  const isAvailable = availableDates?.includes(
    moment(selectedDateCaptur)?.format('DD/MM/YYYY')
  );

  const buttons = [
    '10AM',
    '11AM',
    '12PM',
    '01PM',
    '02PM',
    '03PM',
    '04PM',
    '05PM',
  ];

  // console.log(value);
  return (
    <div className='Calendar-planifier'>
      <h4>Choisissez une date</h4>
      <Calendar
        onChange={(v) => setValue('date', v)}
        value={watch('date')}
        nextLabel={<Icon name='arrow-right' />}
        prevLabel={<Icon name='arrow-left' />}
        tileDisabled={({ date }) =>
          [0, 6].includes(date.getDay()) ||
          availableDates?.includes(moment(date)?.format('DD/MM/YYYY'))
            ? 'disabled'
            : ''
        }
      />
      <div className='input-container'>
        <span className='error'>
          {`${errors && errors?.date?.message ? errors?.date?.message : ''}`}
        </span>
      </div>
      <h4>Choisissez un crénau horraire</h4>
      <div className='time'>
        {buttons?.map((b) => (
          <button
            key={b}
            type='button'
            className={watch('time') === b ? 'active' : ''}
            onClick={() => setValue('time', b)}
          >
            {b}
          </button>
        ))}
      </div>
      <div className='input-container'>
        <span className='error'>
          {`${errors && errors?.time?.message ? errors?.time?.message : ''}`}
        </span>
      </div>
      <p>
        Horaires disponibles selon votre fuseau horaire (Casablanca - GMT+1)
      </p>
    </div>
  );
}

export default Calendar_Planifier;
