import React, { Suspense, lazy } from 'react';
import { useQuery } from 'react-query';
import { afficherDocument } from '../../utils/api/documents';

const PdfReader = lazy(() => import('../../components/PdfReader/PdfReader'));

function ShowDocument({ id }) {
  const { data: document, isLoading: isLoadDoc } = useQuery(
    `document/${id}`,
    id ? () => afficherDocument(id) : () => {}
  );
  if (id && document?.url)
    return (
      <Suspense fallback={<>Loading...</>}>
        <PdfReader url={document?.url} />
      </Suspense>
    );
}

export default ShowDocument;
