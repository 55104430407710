import React, { useEffect, useRef, useState } from "react";
import { items_3d } from "../../data";
import listObjects from "./data.json";
import { useQuery } from "react-query";
import { getAllStagings, getStaging } from "../../utils/api/staging";
import { useParams } from "react-router-dom";

function ShowStaging() {
  const MATTERPORT_KEY = "nt5aaqqp900pq3hh388uc3yra";
  const modelId = "j4RZx7ZGM6T";
  const ref = useRef();
  const refIframe = useRef();
  const [SDK, setSDK] = useState();
  const [sceneObject, setSceneObject] = useState();
  const [selected, setSelected] = useState();
  const [Control, setControl] = useState();
  const url = "";
  const { id } = useParams();
  const { data: staging } = useQuery("staging", () => getStaging(id));

  function poseObserver(pose) {
    // use the current pose of the camera
    // console.log('pose', pose);
    const { position, rotation } = pose;
    // setPosition({ x: position?.x, y: position?.y - 1, z: position.z });
    // setRotation(rotation);
  }

  useEffect(() => {
    const showcase = document.getElementById("showcase");

    showcase.addEventListener("load", async function () {
      let sdk;
      try {
        sdk = await showcase.contentWindow.MP_SDK.connect(
          showcase,
          MATTERPORT_KEY,
          "3.6"
        );
        setSDK(sdk);
        console.log({ sdk });
      } catch (e) {
        console.error(e);
        return;
      }

      console.log(
        "%c  Hello Bundle SDK! ",
        "background: #333333; color: #00dd00"
      );

      const [sceneObject] = await sdk.Scene.createObjects(1);

      const lights = sceneObject.addNode();
      lights.addComponent("mp.lights");
      lights.start();
      setSceneObject(sceneObject);

      sdk.Camera.pose.subscribe(poseObserver);
    });

    return () => {};
  }, []);

  useEffect(() => {
    try {
      if (sceneObject) {
        staging?.objects?.map((i) => {
          let new_item = {
            ...items_3d[i?.index],
            position: i.position,
            rotation: i?.rotation,
            scaleObj: i?.scale,
          };

          print_3d_items(new_item);
        });
      }
    } catch (error) {
      console.log(error);
    }
  }, [sceneObject, staging]);

  const print_3d_items = async (item, show = true) => {
    const modelNode = sceneObject.addNode();
    const tableComponent = modelNode.addComponent(item?.type, {
      url: url + item?.url,
    });

    tableComponent.inputs.localScale = item?.scale;

    const { x, y, z } = item.position;

    modelNode.obj3D.position.set(x, y, z);

    const { x: sx, y: sy, z: sz } = item.scaleObj;

    modelNode.obj3D.scale.set(sx, sy, sz);

    const { _x, _y, _z } = item.rotation;
    modelNode.obj3D.rotation.set(_x, _y, _z);

    // Create a scene node with a transform control component.
    const controlNode = sceneObject.addNode();
    const myControl = controlNode.addComponent("mp.transformControls");

    // Attach the model to the transform control
    // myControl.inputs.selection = modelNode;

    // set 'translate' mode to position the selection.
    // myControl.inputs.mode = 'rotate';

    // const inputComponent = modelNode.addComponent("mp.input", {
    //   eventsEnabled: true,
    //   userNavigationEnabled: false,
    // });

    // Define an emit path to listen for mouse click emits
    const clickEmitPath = sceneObject.addEmitPath(
      tableComponent,
      "INTERACTION.CLICK"
    );

    console.log(clickEmitPath);

    // Define a click event spy
    const clickSpy = sceneObject.spyOnEvent({
      path: clickEmitPath,
      onEvent(payload) {
        console.log("received", payload, clickEmitPath);
        setSelected(modelNode);
        setControl(myControl);
        myControl.inputs.selection = null;
      },
    });

    if (show) {
      modelNode.start();
      controlNode.start();
    } else {
      modelNode.stop();
      controlNode.stop();
    }
  };
  return (
    <div className="ShowStaging" style={{ height: "100dvh" }}>
      <iframe
        id="showcase"
        ref={refIframe}
        src={`/showcase-bundle/showcase.html?&brand=0&mls=2&mt=0&search=0&kb=0&play=1&qs=1&applicationKey=${MATTERPORT_KEY}&m=${
          staging?.matterportId || modelId
        }`}
        frameBorder="0"
        allow="xr-spatial-tracking"
        allowFullScreen
        width={"100%"}
        height={"100%"}
      ></iframe>
    </div>
  );
}

export default ShowStaging;
