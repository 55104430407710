import { Outlet } from 'react-router-dom';
import AppLayout from '../AppLayout';
import Avatar from '../../screens/Avatar/Avatar';
import CallNavigation from '../../screens/Avatar/CallNavigation/CallNavigation';
import { lazy } from 'react';
import CallLayout from '../../screens/Avatar/CallNavigation/CallLayout';
import CallData from '../../screens/Avatar/CallData/CallData';
const CallCalendar = lazy(() =>
  import('../../screens/Avatar/CallCalendar/CallCalendar')
);

const avatarRouter = {
  path: '/avatar',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    {
      path: '',
      element: <Avatar />,
    },
    
    {
      path: ':id',
      element: (
        <CallLayout>
          <Outlet />
        </CallLayout>
      ),
      children: [
        {
          path: 'start',
          element: <CallNavigation />,
        },
        {
          path: 'calendar',
          element: <CallCalendar />,
        },
        {
          path: 'data',
          element: <CallData />,
        },
      ],
    },
  ],
};

export { avatarRouter };
