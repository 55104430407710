import React from 'react';
import './Proprietes.css';
import HomeCard2 from '../../components/Dashboard/HomeCard/HomeCard2';
import { Link } from 'react-router-dom';
import Icon from '../../components/Icon/Icon';

import { useQuery } from 'react-query';
import { getProperties } from '../../utils/api/properties';
function Proprietes() {
  const { data: properties, isLoading } = useQuery('properties', getProperties);

  return (
    <div className='Proprietes-container'>
      <Link to={`/proprietes/ajouter`}>
        <button className='add'>
          <Icon name='add-cercle' />
          Ajouter
        </button>
      </Link>
      <div className='Proprietes'>
        {properties?.map((propriete, i) => (
          <HomeCard2 key={i} propriete={propriete} />
        ))}
      </div>
    </div>
  );
}

export default Proprietes;
