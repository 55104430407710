import React, { useState } from 'react';
import './CaptureService.css';
import Icon from '../../components/Icon/Icon';
import { Link } from 'react-router-dom';

function CaptureService() {
  const [muted, setMuted] = useState(true);

  return (
    <div className='CaptureService'>
      <div className='hero'>
        <video
          src='https://s3-figma-videos-production-sig.figma.com/video/1351682136357418833/TEAM/4e22/823b/-cd31-4b05-a2b7-750d1bac8a3d?Expires=1717977600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=doEw~2hykAnV2DhNM9dvy2e1NayHI4QlAtZFjc23j8fdA0Wnti0KtltvHQIVR0M5SQCVQI3eW6I1E~~E5udzIDeqD7y-81UBjYHDwSjnUp1h--jmQwHMo6kxRe13NmYo3LzET0ZQPQlxWo29wVbQibdjcrfOQALEECszk-k0dqiCva2-5fRukBMoiRtp95XODTkggGNV46rFQQDirKgGJ7DX807PcSI42sOtptDRxL6NTUMb1mAo0bFhZieth1hJkBdTmPIjmIimteQWe0suJRrqhSIdZHAvscVu8Y5-r2LoqGdKbANqDfrI3XYCsdkG7DL3so9BwHaeFQNlK3b2~g__'
          autoPlay={true}
          muted={muted}
          loop={true}
        ></video>
        <div className='info'>
          <h1>Résérvez votre visite virtuelle</h1>
          <p>à partir fr 3700 Mad</p>
          <div className='flex'>
            <Link
              to={'/capture_service/réserver_solutions_de_capture_numérique'}
              className='btn-out'
            >
              Réservez une visite
            </Link>
            <button onClick={() => setMuted((v) => !v)}>
              Cliquez pour {muted ? 'activer' : 'désactiver'} le son
            </button>
          </div>
        </div>
      </div>
      <div className='flex'>
        <div className='Numerisez'>
          <div className='flex'>
            <h2>
              Numérisez <br />
              vos espaces{' '}
            </h2>
            <Icon name='box' />
          </div>
          La méthode la plus sûre et la plus commode pour obtenir un modèle
          numérique de haute qualité de votre espace. C'est très simple !
          Envoyez-nous une description de votre espace, indiquez-nous quand et
          comment nous pouvons intervenir, et nous prendrons en charge le reste
          ! Service maintenant disponible dans plusieurs régions.
          <ul>
            <li>Numérisation effectuée pour vous</li>
            <li>Caméra Matterport haut de gamme</li>
            <li>Technicien spécialisé en capture 3D</li>
          </ul>
        </div>
        <div className='Processus'>
          <h4>Processus de Commande</h4>
          <div className='etap'>
            <div className='number'>1</div>
            <div>
              <p className='bold'>Commander</p>
              <p>
                Commencez par envoyer une demande avec l'adresse de votre espace
                ainsi que le créneau horaire souhaité pour la numérisation.
              </p>
            </div>
          </div>
          <div className='etap'>
            <div className='number'>2</div>
            <div>
              <p className='bold'>Capturer</p>
              <p>
                Un technicien certifié viendra numériser votre espace en
                utilisant notre appareil photo Matterport de pointe.
              </p>
            </div>
          </div>

          <div className='etap'>
            <div className='number'>3</div>
            <div>
              <p className='bold'>Commander</p>
              <p>
                Vous recevrez un modèle numérique de votre espace avec des
                options complètes de retouche et de personnalisation.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CaptureService;
