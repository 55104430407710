import Dashboard from '../../screens/Dashboard/Dashboard';
import DashboardLocataire from '../../screens/Dashboard/DashboardLocataire';
import DashboardTechnicien from '../../screens/Dashboard/DashboardTechnicien';
import AppLayout from '../AppLayout';

const dashboardRouter = {
  path: '/',
  element: (
    <AppLayout>
      <Dashboard />
    </AppLayout>
  ),
};

const dashboardTechnicienRouter = {
  path: '/',
  element: (
    <AppLayout>
      <DashboardTechnicien />
    </AppLayout>
  ),
};

const dashboardLocatairRouter = {
  path: '/',
  element: (
    <AppLayout>
      <DashboardLocataire />
    </AppLayout>
  ),
};

export { dashboardRouter, dashboardLocatairRouter, dashboardTechnicienRouter };
