import React from 'react';
import { useForm } from 'react-hook-form';
import Icon from '../../Icon/Icon';

function Designation({ setValue, watch }) {
  const { register, handleSubmit, getValues, reset } = useForm();
  const list = watch('designation') || [];
  const setItem = () => {
    const { designation, Qté, prix } = getValues();
    if (designation && Qté && prix) {
      const Montant = prix * Qté;
      const l = [
        ...list,
        { designation, Qté, prix, TVA: 0.2 * Montant, Montant },
      ];
      setValue('designation', l);
      let total = 0;
      l?.map(({ Montant }) => (total += Number(Montant)));
      setValue('subtotal', total);
      setValue('tva', (20 * total) / 100);
      setValue('total', total + (20 * total) / 100);
      reset();
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        gap: 10,
        border: '1px solid #E4E7EB',
        padding: 10,
        borderRadius: 10,
        background: '#FAFAFA',
      }}
    >
      <div className='input-container'>
        <label htmlFor='Loyer'>Designation</label>
      </div>
      {list?.map((l, i) => (
        <p
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            padding: 5,
            borderBottom: 'solid 1px #eee',
          }}
          key={i}
        >
          <span
            style={{
              maxWidth: 160,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {l?.designation}
          </span>
          <span> {l?.prix} dh</span>
          <button
            type='button'
            style={{ color: 'red', fontSize: 10 }}
            onClick={() =>
              setValue(
                'designation',
                list?.filter((_, j) => i !== j)
              )
            }
          >
            <Icon name='close' />
          </button>
        </p>
      ))}
      <div className='input-container full'>
        <div style={{ display: 'flex' }}>
          <input
            {...register('designation')}
            type='text'
            placeholder='Designation'
          />
          <input
            type='number'
            placeholder='Qté'
            {...register('Qté')}
            style={{ maxWidth: 100 }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <input {...register('prix')} type='number' placeholder='Prix' />
          {/* <input
            type='number'
            placeholder='GST'
            {...register('GST')}
            style={{ maxWidth: 100 }}
          /> */}
        </div>
      </div>

      <button
        type='button'
        onClick={setItem}
        className='add full '
        style={{ maxWidth: '100%' }}
      >
        Ajouter une designation
      </button>
    </div>
  );
}

export default Designation;
