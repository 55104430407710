import { popup } from '../../components/Popup/Popup';
import { queryClient } from '../../layouts/ReactQueryLayout';
import { Axios } from './axios';

const createCapture = (data) => {
  return Axios.post(`/capture-service`, data).then(({ data }) => {
    queryClient.invalidateQueries('captures');
    popup.show({ title: 'Ajouter', type: 'notification' });
    return data;
  });
};

const getAllCaptures = () => {
  return Axios.get(`/capture-service`).then(({ data }) => {
    console.log({ captures: data });
    return data;
  });
};

const getCapture = (id) => {
  return Axios.get(`/capture-service/${id}`).then(({ data }) => {
    console.log({ capture: data });
    return data;
  });
};

const updateCapture = (id, data) => {
  return Axios.put(`/capture-service/${id}`, data).then(({ data }) => {
    queryClient.invalidateQueries('captures');

    return data;
  });
};

const getAvailableDates = () => {
  return Axios.get(`/gestion/`).then(({ data }) => {
    console.log({ unavailableDays: data?.unavailableDays });
    return data?.unavailableDays;
  });
};
const addAvailableDate = (data) => {
  return Axios.post(`/gestion/`, data).then(({ data }) => {
    queryClient.invalidateQueries('gestion');
    return data;
  });
};

const deleteAvailableDate = (data) => {
  return Axios.put(`/gestion/`, data).then(({ data }) => {
    queryClient.invalidateQueries('gestion');
    return data;
  });
};

export {
  createCapture,
  getAllCaptures,
  getCapture,
  updateCapture,
  getAvailableDates,
  addAvailableDate,
  deleteAvailableDate,
};
