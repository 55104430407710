import React, { useState } from 'react';
import Table from '../../components/Table/Table';
import { Link, useNavigate } from 'react-router-dom';
import Icon from '../../components/Icon/Icon';
import './Paiement.css';
import { useQuery } from 'react-query';
import { afficherTousPaiements } from '../../utils/api/paiement';
import AddPaiementMin from './AddPaiement/AddPaiementMin';
import { useForm } from 'react-hook-form';
import moment from 'moment';

function Paiement() {
  const default_data = [
    {
      'ID  de décaissement': '096',
      émetteur: 'Said Roman',
      Date: '03-04-2024',
      'Somme (MAD)': '7600',
      Moyen: 'Transfer',
      Objet: 'Loyer',
      Statut: 'Traité',
      Bénéficiaire: 'Salim fays',
      Action: 'ID: SF029302979',
    },
    {
      'ID  de décaissement': '096',
      émetteur: 'Hiba Abouk',
      Date: '03-04-2024',
      'Somme (MAD)': '7600',
      Moyen: 'Virement',
      Objet: 'Loyer',
      Statut: 'Traité',
      Bénéficiaire: 'Salim fays',
      Action: 'ID: SF029302979',
    },
    {
      'ID  de décaissement': '096',
      émetteur: 'Sina look',
      Date: '03-04-2024',
      'Somme (MAD)': '7600',
      Moyen: 'Espèce',
      Objet: 'Loyer',
      Statut: 'En cours',
      Bénéficiaire: 'Salim fays',
      Action: 'ID: SF029302979',
    },
    {
      'ID  de décaissement': '096',
      émetteur: 'Layt Koman',
      Date: '03-04-2024',
      'Somme (MAD)': '7600',
      Moyen: 'Transfert',
      Objet: 'Entretien',
      Statut: 'En cours',
      Bénéficiaire: 'Technicien',
      Action: 'ID: SF029302979',
    },
    {
      'ID  de décaissement': '096',
      émetteur: 'Layt Koman',
      Date: '03-04-2024',
      'Somme (MAD)': '7600',
      Moyen: 'Virement',
      Objet: 'Loyer',
      Statut: 'En cours',
      Bénéficiaire: 'Salim fays',
      Action: 'ID: SF029302979',
    },
    {
      'ID  de décaissement': '096',
      émetteur: 'Layt Koman',
      Date: '03-04-2024',
      'Somme (MAD)': '7600',
      Moyen: 'Virement',
      Objet: 'Entretien',
      Statut: 'En cours',
      Bénéficiaire: 'Salim fays',
      Action: 'ID: SF029302979',
    },
    {
      'ID  de décaissement': '096',
      émetteur: 'Layt Koman',
      Date: '03-04-2024',
      'Somme (MAD)': '7600',
      Moyen: 'Virement',
      Objet: 'Loyer',
      Statut: 'En cours',
      Bénéficiaire: 'Salim fays',
      Action: 'ID: SF029302979',
    },
  ];

  const { data: payments, isLoading } = useQuery(
    'payments',
    afficherTousPaiements
  );

  const keys = [
    'object',
    'montant',
    'beneficiare',
    // 'first_name',
    // 'last_name',
    'payment_method',
    'createdAt',
  ];

  const header = [
    'Objet',
    'Montant (MAD)',
    'Bénéficiaire',
    // 'Prénom',
    // 'Nom',
    'Payer par',
    'Date',
  ];

  const objects = {
    admin: 'Payez votre abonnement',
    proprietaire: 'Paiement du loyer',
    technicien: 'Paiement au technicien',
  };

  const { data: profile, error } = useQuery('profile');
  const router = useNavigate();
  const { cardNumber, cardHolderName, expirationDate } = profile?.card || {};
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({});

  return (
    <div className='Paiement'>
      {profile?.role !== 'Admin' && (
        <div className='payment-data'>
          <div className='credit-card'>
            <img src='/images/cih.svg' alt='' />
            <button className='edit'>
              <Icon name='edit' />
            </button>
            <div className='info'>
              <div className='top'></div>
              <h3>{cardNumber?.replace(/(.{4})/g, '$1  ')}</h3>
              <p>
                <span className='name' style={{ width: 150 }}>
                  {cardHolderName}
                </span>
                <span className='date'>
                  <span>Expire le</span>
                  {expirationDate}
                </span>
              </p>
            </div>
          </div>
          <div className='settings'>
            <div className='switch'>
              <input type='checkbox' name='' id='' />
            </div>
            <p>
              Désactiver la carte <br />
              <br />
            </p>

            <p>Limite hebdomadaire</p>
            <p className='bold'>35K MAD / 40K MAD</p>
            <div className='slide'>
              <div></div>
            </div>
          </div>
          <form
            action=''
            onSubmit={handleSubmit((data) => {
              let params = '';
              Object.keys(data).map((k) => (params += `${k}=${data[k]}&`));
              router({
                pathname: '/paiements/add',
                search: params,
              });
            })}
          >
            <AddPaiementMin
              {...{ register, errors, profile, watch, setValue }}
            />
            <button className='add'>
              <Icon name='checkout' /> Checkout
            </button>

            <Link to={'/paiements/add'}>Afficher tout</Link>
          </form>
        </div>
      )}

      <Table
        // show_filter={false}
        keys={header}
        isLoading={isLoading}
        values={payments || []}
        // getList={(d) => setData(d)}
        list={payments || []}
      >
        {({ List }) =>
          List?.map((v, i) => (
            <tr key={i}>
              {keys?.map((k, j) => {
                switch (k) {
                  case 'ID  de décaissement':
                    return (
                      <td key={j}>
                        <div className='flex'>
                          <Icon
                            name='success'
                            className={'primary-color'}
                            style={{ fontSize: 18 }}
                          />
                          {v[k]}
                        </div>
                      </td>
                    );
                  case 'Action':
                    return;
                  case 'beneficiare':
                    return (
                      <td key={j}>
                        {v?.beneficiare?.first_name} {v?.beneficiare?.last_name}
                      </td>
                    );

                  case 'object':
                    return <td key={j}>{objects[v?.object || '']}</td>;
                  case 'createdAt':
                    return (
                      <td key={j}>
                        {moment(v?.createdAt)?.format('DD/MM/YYYY (hh:mm)')}
                      </td>
                    );

                  case 'Statut':
                    return (
                      <td key={j}>
                        {
                          <span
                            className={
                              v['Statut'] !== 'Inactif' ? 'success' : 'dark'
                            }
                          >
                            {v['Statut']}
                          </span>
                        }
                      </td>
                    );

                  default:
                    return <td key={j}>{v[k]}</td>;
                }
              })}

              <td>
                <Link to={`/paiements/${v?._id}`}>
                  <Icon name='dots' />
                </Link>
              </td>
            </tr>
          ))
        }
      </Table>
    </div>
  );
}

export default Paiement;
