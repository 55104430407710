import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './Chart.css';

function Chart() {
  const data = {
    series: [
      {
        name: 'series1',
        data: [0, 1000, 2000, 500, 2500, 3000, 1000],
        color: '#695CFB',
      },
      {
        name: 'series2',
        data: [0, 1000, 2500, 3000, 1000, 2000, 500],
        color: '#00C7F2',
      },
      {
        name: 'series3',
        data: [0, 3000, 1000, 2000, 500, 1000, 2500],
        color: '#0FCA7A',
      },
      {
        name: 'series4',
        data: [500, 1000, 0, 3000, 1000, 2000, 2500],
        color: '#F7A23B',
      },
    ],
    options: {
      chart: {
        height: 400,
        type: 'line',
        width: '100%',
        stacked: false,

        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          borderRadius: 5,
          borderRadiusOnAllStackedSeries: true,
        },
      },
      legend: {
        show: false,
      },
      toolbar: {
        show: false,
      },

      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        width: 1.5,
      },

      fill: {
        type: 'solid',

        opacity: 0,
      },

      xaxis: {
        type: 'datetime',
        labels: {
          style: {
            colors: [
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
            ],
          },
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMMM',
            day: 'dd MMM',
            hour: 'HH:mm',
          },
        },
        categories: [
          '2018-05-19T02:30:00.000Z',
          '2018-06-19T01:30:00.000Z',
          '2018-07-19T00:00:00.000Z',
          '2018-08-19T03:30:00.000Z',
          '2018-09-19T04:30:00.000Z',
          '2018-10-19T05:30:00.000Z',
          '2018-11-19T06:30:00.000Z',
        ],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value + 'MAD';
          },
          style: {
            colors: [
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
              '#72777B',
            ],
          },
        },
      },
    },
  };

  return (
    <div className='chart'>
      <div className='flex'>
        évolution des ventes / mois{' '}
        <div className='values'>
          <div>
            <span />
            Villas
          </div>
          <div>
            <span />
            Appartements
          </div>
          <div>
            <span />
            Studios
          </div>
          <div>
            <span />
            Loyer
          </div>
        </div>
      </div>

      <div className='chart-container'>
        <ReactApexChart
          options={data.options}
          series={data.series}
          type='area'
          width={'100%'}
          height={'100%'}
        />
      </div>
      <p>MOIS</p>
    </div>
  );
}

export default Chart;
