import React, { useEffect } from 'react';
import Select from '../../../components/Inputs/Select';
import Input from '../../../components/Inputs/Input';
import SelectTechnicien from './SelectUsers/SelectTechnicien';
import SelectProprietaire from './SelectUsers/SelectProprietaire';
import SelectProprieté from './SelectUsers/SelectProprieté';

function AddPaiementMin({ register, errors, profile, watch, setValue }) {
  useEffect(() => {
    if (watch('object') === 'admin') setValue('beneficiare', 'admin');
    // if (watch('object') === 'technicien') setValue('beneficiare', '');
    // if (watch('object') === 'proprietaire') setValue('beneficiare', '');
  }, [watch('object')]);

  if (profile)
    return (
      <>
        <h2>Effectuer un Paiement</h2>
        <div className='flex full '>
          <Select
            {...{
              register,
              errors,
              lable: 'Objet',
              name: 'object',
            }}
          >
            {profile?.role === 'Propriétaire' ? (
              <option value='admin'>Payez votre abonnement</option>
            ) : (
              <option value='proprietaire'>Paiement du loyer</option>
            )}
            <option value='technicien'>Paiement au technicien</option>
          </Select>

          <Input
            {...{
              register,
              errors,
              lable: 'Montant (MAD)',
              name: 'montant',
              type: 'number',
              attr: { disabled: watch('object') === 'proprietaire' },
            }}
          />

          {watch('object') === 'technicien' && (
            <>
              <SelectTechnicien
                {...{ register, errors, profile, watch, setValue }}
              />
              <SelectProprieté
                {...{
                  register,
                  errors,
                  profile,
                  watch,
                  setValue,
                  lable: 'Unité',
                  name: 'unité',
                }}
              />
            </>
          )}
          {watch('object') === 'proprietaire' && (
            <>
              <SelectProprietaire
                {...{
                  register,
                  errors,
                  profile,
                  watch,
                  setValue,
                  lable: 'Bénéficiaire',
                  name: 'beneficiare',
                }}
              />
              <SelectProprieté
                {...{
                  register,
                  errors,
                  profile,
                  watch,
                  setValue,
                  lable: 'Unité',
                  name: 'unité',
                }}
              />
            </>
          )}

          {watch('object') === 'admin' && (
            <Select
              {...{
                register,
                errors,
                lable: 'Bénéficiaire',
                name: 'beneficiare',
              }}
            >
              <option value='admin' selected>
                Admin
              </option>
            </Select>
          )}
        </div>
      </>
    );
}

export default AddPaiementMin;
