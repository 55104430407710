import React from "react";
import "./Upload.css";
import Icon from "../Icon/Icon";
import { upload } from "../../utils/api/upload";
import toast from "react-hot-toast";

function Upload({
  onUpload = (url, filename) => {},
  src,
  isImage = true,
  hiden = false,
}) {
  const handleFileChange = (e) => {
    if (e.target.files[0]?.size > 30000000)
      return toast.error("la taille de fichier est volumineuse (> 30 MB)");
    upload(e.target.files[0])
      .then(({ file }) => {
        onUpload(file, e.target.files[0].name);
      })
      .catch((err) => console.log(err));
  };

  if (isImage)
    return (
      <div className={`upload upload-button ${hiden ? "hiden" : ""}`}>
        <input type="file" accept="image/*" onChange={handleFileChange} />
        <img src={src || "/images/upload.svg"} />
        {src && (
          <button className="edit">
            <Icon name="edit" />
          </button>
        )}
      </div>
    );

  return (
    <>
      <button
        type="button"
        className={`upload upload-button ${hiden ? "hiden" : ""}`}
      >
        <input
          type="file"
          onChange={handleFileChange}
          style={{ width: "100%", left: 0 }}
        />
        <div>
          Joindre la CIN / passport
          <Icon name="join" />
        </div>
        <span>Pdf, word, JPG ( 2MB )</span>
      </button>
    </>
  );
}

export default Upload;
