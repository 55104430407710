import { Outlet } from 'react-router-dom';
import AppLayout from '../AppLayout';
import Proprietes from '../../screens/Proprietes/Proprietes';
import Propriete from '../../screens/Proprietes/Propriete/Propriete';
import UpdateProperieteOwner from '../../screens/Proprietes/UpdateProperieteOwner';
import AddProperieteOwner from '../../screens/Proprietes/AddProperieteOwner';
import AddProperiete from '../../screens/Proprietes/AddProperiete';
import UpdateProperiete from '../../screens/Proprietes/UpdateProperiete';

const proprietesRouter = {
  path: '/proprietes',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    { path: '', element: <Proprietes /> },
    { path: 'ajouter', element: <AddProperiete /> },
    { path: 'modifier/:id', element: <UpdateProperiete /> },
    { path: ':id', element: <Propriete /> },
  ],
};

const proprietesOwenerRouter = {
  path: '/proprietes',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    { path: '', element: <Proprietes /> },
    { path: 'ajouter', element: <AddProperieteOwner /> },
    { path: 'modifier/:id', element: <UpdateProperieteOwner /> },
    { path: ':id', element: <Propriete /> },
  ],
};

const locataireProprietesRouter = {
  path: '/proprietes/:id',
  element: (
    <AppLayout>
      <Propriete />
    </AppLayout>
  ),
};

export { proprietesRouter, locataireProprietesRouter, proprietesOwenerRouter };
