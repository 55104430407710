import React, { Suspense, lazy } from 'react';
const PdfCreator = lazy(() => import('../../PdfCreator/PdfCreator'));
import FicheDInterventionDeMaintenance from '../../../components/Docs/FicheDInterventionDeMaintenance';
import FicheDInterventionDeMaintenanceForm from '../../../components/Docs/Forms/FicheDInterventionDeMaintenanceForm';
import { ajouterFicheIntervention } from '../../../utils/api/documents';

function FicheDInterventionPage() {
  return (
    <Suspense fallback={<>Loading...</>}>
      <PdfCreator
        type={'fiche_intervention_maintenance'}
        Element={FicheDInterventionDeMaintenance}
        Form={FicheDInterventionDeMaintenanceForm}
        onsubmit={ajouterFicheIntervention}
      />
    </Suspense>
  );
}

export default FicheDInterventionPage;
