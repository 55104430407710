import React, { useState } from 'react';
import './RendezVous.css';
import Calendar_Planifier from '../../components/Calendar/Calendar';
import Calendar from 'react-calendar';
import Icon from '../../components/Icon/Icon';
import { Link } from 'react-router-dom';
import Demandes from './Demandes';
import { useQuery } from 'react-query';
import {
  addAvailableDate,
  deleteAvailableDate,
  getAvailableDates,
} from '../../utils/api/capture';
import moment from 'moment';

function RendezVous() {
  const { data: availableDates } = useQuery('gestion', getAvailableDates);
  const [selectedDateCaptur, setSelectedDateCaptur] = useState();

  const isAvailable = availableDates?.includes(
    moment(selectedDateCaptur)?.format('DD/MM/YYYY')
  );

  const updateAvailabeDates = () => {
    isAvailable
      ? deleteAvailableDate({
          day: moment(selectedDateCaptur)?.format('DD/MM/YYYY'),
        })
          .then((data) => {})
          .catch((err) => {})
      : addAvailableDate({
          day: moment(selectedDateCaptur)?.format('DD/MM/YYYY'),
        })
          .then((data) => {})
          .catch((err) => {});
  };

  if (availableDates)
    return (
      <div className='RendezVous'>
        <div className='calendar-container'>
          <div className='top'>
            <div> </div>
            <h4>Capture service</h4>

            <img src='/images/3d-camera.png' alt='' />
          </div>
          <div className='body'>
            <Link to={'/rendez-vous'}>Gestion de disponibiltés</Link>
            <Calendar
              onChange={setSelectedDateCaptur}
              // value={value}
              tileDisabled={({ date }) => [0, 6].includes(date.getDay())}
              tileClassName={({ date }) =>
                [0, 6].includes(date.getDay()) ||
                availableDates?.includes(moment(date)?.format('DD/MM/YYYY'))
                  ? 'disabled'
                  : ''
              }
              nextLabel={<Icon name='arrow-right' />}
              prevLabel={<Icon name='arrow-left' />}
            />
            {selectedDateCaptur && (
              <button
                className='add'
                onClick={updateAvailabeDates}
                style={{
                  maxWidth: '100%',
                  background: !isAvailable ? 'red' : '#377dff',
                }}
              >
                Marquez comme {!isAvailable ? 'in' : ''}disponible
              </button>
            )}
          </div>
        </div>
        <div className='calendar-container'>
          <div className='top' style={{ background: '#000' }}>
            <div> </div>
            <h4>Visites virtuelles</h4>
            <img src='/images/matterport-3d.png' alt='' />
          </div>
          <div className='body'>
            <Link to={'/rendez-vous'}>Gestion de disponibiltés</Link>
            <Calendar
              // onChange={onChange}
              // value={value}
              nextLabel={<Icon name='arrow-right' />}
              prevLabel={<Icon name='arrow-left' />}
            />
          </div>
        </div>
        <Demandes />
      </div>
    );
}

export default RendezVous;
