import React from 'react';
import Icon from './Icon/Icon';
import Maps from './Maps/Maps';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteProperty } from '../utils/api/properties';

function FormPropertDetails({ register, setValue, errors, watch }) {
  const { id } = useParams();
  const router = useNavigate();

  const DeleteProprety = () => {
    deleteProperty(id)
      .then((d) => router('/proprietes'))
      .catch((err) => console.log(err));
  };

  return (
    <div className='more-details'>
      <h2>Lien de localisation Maps</h2>
      <div className='flex ' style={{ gap: 20 }}>
        <div className='input-container  ' style={{ minWidth: 100 }}>
          <label htmlFor=''>Latitude</label>
          <input
            type='number'
            placeholder='Latitude'
            className={`${errors && errors['latitude'] ? 'error' : ''}`}
            {...register('latitude', { valueAsNumber: true })}
            onChange={(e) => setValue('latitude', e.target.value)}
          />
          <span className='error'>
            {`${
              errors && errors['latitude']?.message
                ? errors['latitude']?.message
                : ''
            }`}
          </span>
        </div>

        <div className='input-container  ' style={{ minWidth: 100 }}>
          <label htmlFor=''>Longitude</label>
          <input
            type='number'
            placeholder='Longitude'
            className={`${errors && errors['longitude'] ? 'error' : ''}`}
            {...register('longitude', { valueAsNumber: true })}
            onChange={(e) => setValue('vrUrl', e.target.value)}
          />
          <span className='error'>
            {`${
              errors && errors['longitude']?.message
                ? errors['longitude']?.message
                : ''
            }`}
          </span>
        </div>
      </div>
      <h2>Aperçu Rapide</h2>

      {/* <iframe
      src={watch('localistionUrl')}

      frameborder='0'
      height={400}
      width={'100%'}
    ></iframe> */}
      <Maps
        position={{ lat: watch('latitude'), lng: watch('longitude') }}
        setPosition={({ lat, lng }) => {
          setValue('latitude', lat);
          setValue('longitude', lng);
        }}
      />
      <h2>Lien de votre visite virtuelle </h2>
      <div className='input-container full'>
        <input
          className={`${errors && errors['vrUrl'] ? 'error' : ''}`}
          {...register('vrUrl')}
          onChange={(e) => setValue('vrUrl', e.target.value)}
          type='text'
          placeholder='.....'
        />
        <span className='error'>
          {`${
            errors && errors['vrUrl']?.message ? errors['vrUrl']?.message : ''
          }`}
        </span>
      </div>
      <h2>Aperçu Rapide</h2>
      <iframe
        src={watch('vrUrl')}
        // frameborder='0'
        height={400}
        width={'100%'}
      ></iframe>
      <div className='flex' style={{ marginTop: 40 }}>
        <button
          className='add'
          style={{
            width: '100%',
            maxWidth: '100%',
          }}
          type='submit'
          form='form-Property'
        >
          <Icon name='add-cercle' />
          Sauvegarder
        </button>
        {id ? (
          <button
            type='button'
            className='add bg-danger'
            style={{
              width: '100%',
              maxWidth: '100%',
            }}
            onClick={() => DeleteProprety()}
          >
            <Icon name='trash' />
            Supprimer
          </button>
        ) : (
          <button
            onClick={() => router('/proprietes')}
            type='button'
            className='add '
            style={{
              width: '100%',
              maxWidth: '100%',
              background: 'none',
              color: '#000',
            }}
          >
            Annuler
          </button>
        )}
      </div>
    </div>
  );
}

export default FormPropertDetails;
