import { Outlet } from 'react-router-dom';
import EtatDesLieux from '../../screens/EtatDesLieux/EtatDesLieux';
import EtatDesLieuxList from '../../screens/EtatDesLieux/EtatDesLieuxList';
import AppLayout from '../AppLayout';

const etatLieuxRouter = {
  path: '/etatLieux',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    {
      path: '',
      element: <EtatDesLieuxList />,
    },
    {
      path: 'ajouter',
      element: <EtatDesLieux />,
    },
  ],
};

export { etatLieuxRouter };
