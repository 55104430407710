import React from 'react';
import Icon from '../Icon/Icon';

function ListItems({
  watch = () => {},
  setValue = () => {},
  item = 'équipement',
  item_name = 'équipement',
}) {
  return (
    <div className='list-item'>
      <div>{item_name}</div>
      <button
        type='button'
        onClick={() => {
          let v = window?.prompt(`Ajouter ${item}`);
          if (v) setValue(item, [...(watch(item) || []), v]);
        }}
      >
        <Icon name={'add-cercle'} /> Ajouter
      </button>

      {(watch(item) || []).map((e, i) => (
        <div key={i} className='item'>
          {e}
          <Icon
            name='close'
            click={() =>
              setValue(
                item,
                watch(item)?.filter((v) => v !== e)
              )
            }
          />
        </div>
      ))}
    </div>
  );
}

export default ListItems;
