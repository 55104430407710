import React from 'react';
import Icon from '../Icon/Icon';

function Apercu({ property }) {
  return (
    <div className='Apercu'>
      <div className='flex'>
        <h2>{property?.unité}</h2>
        <h3>
          {property?.price} <span>MAD</span>
          <span>/Mois</span>
        </h3>
      </div>
      <h4>Description</h4>
      <p>{property?.description}</p>
      <h4>Caractéristiques :</h4>
      {/* <button>
        <Icon name='bathroom' />
        {property.adresse}
      </button> */}
      <div>
        {property?.conditions?.map((e, i) => (
          <li className='item' key={i}>
            {e}
          </li>
        ))}
      </div>
      <h4>Propriétaire / manager</h4>
      <div className='flex'>
        <button>
          <Icon name='user' />
          {property?.owner?.user?.first_name} {property?.owner?.user?.last_name}
        </button>
        <a href={`tel:${property?.owner?.user?.phone}`}>
          <button>
            <Icon name='phone' />
            {property?.owner?.user?.phone}
          </button>
        </a>
      </div>
    </div>
  );
}

export default Apercu;
