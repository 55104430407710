import React, { useState } from 'react';
import Icon from '../../Icon/Icon';
import { useForm } from 'react-hook-form';
import SelectProprieté from '../../../screens/Paiement/AddPaiement/SelectUsers/SelectProprieté';
import { useQuery } from 'react-query';
import Input from '../../Inputs/Input';
import SelectTechnicien from '../../../screens/Paiement/AddPaiement/SelectUsers/SelectTechnicien';
import SelectUser from '../../../screens/Paiement/AddPaiement/SelectUsers/SelectUser';
import { zodResolver } from '@hookform/resolvers/zod';
import { FicheSchema } from '../../../utils/zod_check';
import TextArea from '../../Inputs/TextArea';

function FicheDInterventionDeMaintenanceForm({ setformData = () => {} }) {
  let defaultValues = {
    unité: '',
    date: '',
    émetteur: '',
    technicien: '',

    description: '',
    intervention_réalisée: '',
    pièces_rempalcés: '',
    de: '',
    à: '',
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: zodResolver(FicheSchema),
  });

  const { data: profile } = useQuery('profile');

  const SendData = (data) => {
    if (profile?.role !== 'Admin') {
      data.émetteur = profile?._id;
      const { first_name, last_name } = profile;
      data.émetteur_name = `${first_name} ${last_name}`;
    }
    console.log(data);
    setformData(data);
  };

  return (
    <form
      className='mx-9 my-4'
      onSubmit={handleSubmit(SendData)}
      // onChange={() => setformData(getValues())}
    >
      <SelectProprieté
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: 'Unité',
          name: 'unité',
        }}
      />

      <Input
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: 'Date',
          name: 'date',
          type: 'date',
        }}
      />
      {/* <div className='input-container'>
        <label htmlFor='email'>Locataire</label>
        <input
          {...register('locataire')}
          type='text'
          placeholder='Lila hadaba'
        />
      </div> */}

      {profile?.role === 'Admin' && (
        <SelectUser
          {...{
            register,
            errors,
            profile,
            watch,
            setValue,
            lable: 'émetteur',
            name: 'émetteur',
          }}
        />
      )}

      <SelectTechnicien
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: 'Technicien',
          name: 'technicien',
        }}
      />

      {/* <Input
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: 'Type de contrat',
          name: 'type_de_contrat',
        }}
      /> */}

      {/* <div className='input-container'>
        <label htmlFor='email'>Intervention Réalisée</label>
        <input
          {...register('intervention_réalisée')}
          type='text'
          placeholder='Intervention Réalisée'
        />
      </div> */}
      {/* <div className='input-container'>
        <label htmlFor='email'>Pièces rempalcés</label>
        <input
          {...register('pièces_rempalcés')}
          type='text'
          placeholder='Pièces rempalcés'
        />
      </div> */}
      {/* <div className='flex-date flex'>
        <div className='input-container'>
          <label htmlFor='email'>De</label>
          <input {...register('de')} type='time' placeholder='Trois' />
        </div>
        <div className='input-container'>
          <label htmlFor='email'>à</label>
          <input {...register('à')} type='time' placeholder='Trois' />
        </div>
      </div> */}

      <TextArea
        {...{
          register,
          errors,
          watch,
          setValue,

          lable: 'Déscription',
          name: 'description',
        }}
      />

      <button className='add full ' style={{ maxWidth: '100%' }}>
        <Icon name='loading' />
        Actualiser
      </button>
    </form>
  );
}

export default FicheDInterventionDeMaintenanceForm;
