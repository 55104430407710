import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Svg,
  Path,
  Font,
} from '@react-pdf/renderer';
import { createTw } from 'react-pdf-tailwind';
import { Table, TR, TH, TD } from '@ag-media/react-pdf-table';
import OutfitThin from '../../assets/fonts/Outfit-SemiBold.ttf';
import moment from 'moment';
Font.register({
  family: 'Outfit',
  src: OutfitThin,
});

const tw = createTw({
  theme: {
    fontFamily: {
      sans: ['italic'],
    },
    extend: {
      colors: {
        custom: 'cornflowerblue',
      },
    },
  },
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    color: 'white',
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    backgroundColor: 'white',
    width: '1249px',
    height: '800px',
  },
  flexContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  text: {
    fontFamily: 'Outfit',
  },
  border: {
    borderBottomColor: '#00000033',
    borderBottomWidth: '0.5px',
    width: '100%',
    marginTop: '3px',
  },
});

const QuitanceDeLoyer = ({
  property,
  locataire,
  beneficiare,
  createdAt,
  montant,
}) => {
  return (
    <Document>
      <Page size='A4' style={{ backgroundColor: '#FFFFFF', paddingTop: 40 }}>
        <View style={[styles.flexContainer, tw('mb-4 px-6')]}>
          <View style={[tw('mb-4 ml-10')]}>
            <Text style={[styles.text, tw('text-lg')]}>Quittance de Loyer</Text>
          </View>

          <View style={[tw('mb-4 ')]}>
            <Text style={[styles.text, tw('text-lg text-white')]}>VRBoost</Text>
            <Text style={[styles.text, tw('text-[12px] text-[#000000cc]')]}>
              Unité 87BE928
            </Text>
            <Text style={[styles.text, tw('text-[12px] text-[#000000cc]')]}>
              {locataire?.email}
            </Text>
            <Text style={[styles.text, tw('text-[12px] text-[#000000cc]')]}>
              www.vrboost.com
            </Text>
          </View>
          <View style={[tw('mb-4')]}>
            <Text style={[styles.text, tw('text-lg')]}>VRBoost</Text>
            <Text style={[styles.text, tw('text-[12px] text-[#000000cc]')]}>
              147 Unité 1243
            </Text>
            <Text style={[styles.text, tw('text-[12px] text-[#000000cc]')]}>
              Casablanca,Maarif,MA
            </Text>
            <Text style={[styles.text, tw('text-[12px] text-[#000000cc]')]}>
              20 000
            </Text>
          </View>
        </View>
        <View style={styles.border}></View>
        <View style={[styles.flexContainer, tw('my-4 px-[4rem]')]}>
          <View style={[tw('mb-4')]}>
            <Text style={[styles.text, tw('text-lg text-[#00000080]')]}>
              Loué à
            </Text>
            <Text style={[styles.text, tw('text-2xl font-semibold')]}>
              {beneficiare?.first_name} {beneficiare?.last_name}
            </Text>

            <Text style={[styles.text, tw('text-[12px]')]}>Adress</Text>
            <Text style={[styles.text, tw('text-[12px]')]}>
              {beneficiare?.email}
            </Text>
            <Text style={[styles.text, tw('text-[12px]')]}>Phone number</Text>
            <Text style={[styles.text, tw('text-[12px]')]}>
              {beneficiare?.phone}
            </Text>
          </View>
          <View style={[tw('mb-4'), { textAlign: 'right' }]}>
            <Text style={[styles.text, tw('text-lg text-[#00000080]')]}>
              Quittance No.
            </Text>
            <Text style={[styles.text, tw('text-[12px] text-[#000000cc]')]}>
              #002653
            </Text>
            <Text style={[styles.text, tw('text-[12px] text-[#00000080]')]}>
              Date
            </Text>
            <Text style={[styles.text, tw('text-[12px] text-[#000000cc]')]}>
              {moment(createdAt)?.format('DD/MM/YYYY')}
            </Text>
            <Text style={[styles.text, tw('text-[12px] text-[#00000080]')]}>
              échéance
            </Text>
            <Text style={[styles.text, tw('text-[12px] text-[#000000cc]')]}>
              {moment(createdAt)?.format('DD/MM/YYYY')}
            </Text>
          </View>
        </View>
        <View style={[tw('mb-4 px-[4rem]')]}>
          <Text
            style={[styles.text, tw('text-[11px] text-[#000000cc] mb-2 ml-3')]}
          >
            Nous vous remercions de votre confiance et sommes heureux de vous
            adresser la présente quittance.
          </Text>
          <View style={styles.border}></View>
        </View>

        <Table style={tw('bg-white rounded-2xl border-[#FFFFFF] px-[4rem]')}>
          <TH style={tw('border-[#F3F4F8]')}>
            <TD
              style={tw(
                'text-[12px] text-[#6B7280] font-bold py-3 pl-3 border-b-[#FFFFFF] border-r-[#FFFFFF]'
              )}
            >
              Description
            </TD>
            <TD
              style={tw(
                'text-[12px] text-[#6B7280] font-bold py-3 pl-3 border-b-[#FFFFFF] border-l-[#FFFFFF]'
              )}
            >
              Loyer Unitaire
            </TD>
            <TD
              style={tw(
                'text-[12px] text-[#6B7280] font-bold py-3 pl-3 border-b-[#FFFFFF] border-l-[#FFFFFF] border-r-[#FFFFFF]'
              )}
            >
              Période
            </TD>
            <TD
              style={tw(
                'text-[12px] text-[#6B7280] font-bold py-3 pl-3 border-b-[#FFFFFF] border-l-[#FFFFFF]'
              )}
            >
              Montant
            </TD>
          </TH>

          <View style={styles.border}></View>
          <TR style={tw('')}>
            <TD
              style={tw(
                'text-[8.7px] text-[#6B7280] font-normal py-3 pl-3  border-r-[#FFFFFF]'
              )}
            >
              <Text style={[styles.text, tw('text-[#000000cc]')]}>
                {property?.unité}
                <Text>{'\n'}</Text>
                <Text style={[styles.text, tw('text-[#00000080] text-[10px]')]}>
                  {property?.address}
                </Text>
              </Text>
            </TD>
            <TD
              style={tw(
                'text-[12px] text-[#000000] font-normal py-3 pl-3 border-l-[#FFFFFF]'
              )}
            >
              {property?.price?.toFixed(2)} MAD
            </TD>
            <TD
              style={tw(
                'text-[12px] text-[#000000] font-normal py-3 pl-3 border-l-[#FFFFFF] border-r-[#FFFFFF]'
              )}
            >
              Février- MARS
            </TD>
            <TD
              style={tw(
                'text-[12px] text-[#000000] font-normal py-3 pl-3 border-r-[#FFFFFF] border-l-[#FFFFFF]'
              )}
            >
              {montant?.toFixed(2)} MAD
            </TD>
          </TR>
          <View style={styles.border}></View>
        </Table>
        <View style={[styles.flexContainer, tw('mb-4')]}>
          <View
            style={{
              display: 'flex',
              marginTop: '10px',
              marginLeft: 'auto',
              marginRight: 50,
              flexDirection: 'row',
              gap: 10,
            }}
          >
            <View>
              <Text style={[styles.text, tw('text-lg')]}> Sous-total: </Text>
              <Text style={[styles.text, tw('text-lg')]}> TVA:</Text>
              <Text style={[styles.text, tw('text-lg')]}> Total:</Text>
            </View>

            <View>
              <Text style={[styles.text, tw('text-lg text-[#000000cc] ')]}>
                {montant?.toFixed(2)} MAD (HT)
              </Text>
              <Text style={[styles.text, tw('text-lg text-[#000000cc] pl-2')]}>
                {((1 / 5) * montant)?.toFixed(2)} MAD (HT)
              </Text>
              <Text style={[styles.text, tw('text-lg text-[#000000cc] pl-2')]}>
                {((1 / 5) * montant + montant)?.toFixed(2)} MAD (HT)
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default QuitanceDeLoyer;
