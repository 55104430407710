import React, { Children, useMemo, useState } from 'react';
import './Table.css';
import Search from '../Search/Search';
import Icon from '../Icon/Icon';

function Table({
  keys = [],
  children,
  show_filter = true,
  isLoading = false,
  list = [],
  show_action = true,
}) {
  const [page, setPage] = useState(1);
  const [List, setList] = useState(null);
  const [limit, setLimit] = useState(0);
  const ItemsPage = 10;

  useMemo(() => {
    if (list?.length > 0) setList(list);
  }, [list]);

  useMemo(() => {
    if (List?.length > 0) {
      let l = List?.length / ItemsPage;
      setLimit(Math.floor(l > Math.floor(l) ? l + 1 : l));
      setPage(1);
    }
  }, [List]);

  return (
    <div className='Table'>
      {show_filter && (
        <div className='filter'>
          <Search getList={(v) => setList(v)} list={list} />
          {/* <button className='filter-button'>
            Filter
            <Icon name='filter' />
          </button>
          <button>
            <Icon name='dots' />
          </button> */}
        </div>
      )}
      <div className='table-container '>
        <table>
          <thead>
            <tr>
              {keys?.map((k, i) => (
                <th key={i}>{k} </th>
              ))}
              {show_action && <th>Action </th>}
            </tr>
          </thead>
          {!isLoading ? (
            <tbody>
              {children({
                List: List?.slice((page - 1) * ItemsPage, page * ItemsPage),
              })}
            </tbody>
          ) : (
            <tbody className='LoadingAnime'>
              {new Array(ItemsPage).fill(0).map((_, key) => (
                <tr key={key}>
                  {keys?.map((k) => (
                    <td key={k}>
                      <div
                        className='anime'
                        style={{ height: 20, width: 100 }}
                      ></div>
                    </td>
                  ))}
                  <td>
                    <div
                      className='anime'
                      style={{ height: 20, width: 100 }}
                    ></div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <div className='pagination'>
        <button
          disabled={page <= 1 ? true : false}
          onClick={() => setPage((p) => p - 1)}
        >
          <Icon name='arrow-left' />
        </button>
        {new Array(limit).fill(0).map((item, i) => (
          <button
            onClick={() => setPage(i + 1)}
            className={page === i + 1 ? 'active' : ''}
            key={i}
          >
            {i + 1}
          </button>
        ))}
        <button
          disabled={page >= limit ? true : false}
          onClick={() => setPage((p) => p + 1)}
        >
          <Icon name='arrow-right' />
        </button>
      </div>
    </div>
  );
}

export default Table;
