import React, { Suspense, lazy } from 'react';
const PdfCreator = lazy(() => import('../../PdfCreator/PdfCreator'));
import QuitanceDeLoyer from '../../../components/Docs/QuitanceDeLoyer';
import QuitanceDeLoyerForm from '../../../components/Docs/Forms/QuitanceDeLoyerForm';
import { useQuery } from 'react-query';
import { afficherPaiement } from '../../../utils/api/paiement';
import { useParams } from 'react-router-dom';
import { ajouterDocument } from '../../../utils/api/documents';

function QuitanceDeLoyerPage() {
  const { id } = useParams();
  const { data: payment, isLoading } = useQuery(`payments/${id}`, () =>
    afficherPaiement(id)
  );
  const { data: profile, error } = useQuery('profile');
  if (!isLoading)
    return (
      <Suspense fallback={<>Loading...</>}>
        <PdfCreator
          type={'payment'}
          value={{ ...payment, locataire: profile }}
          Element={QuitanceDeLoyer}
          onsubmit={ajouterDocument}
        />
      </Suspense>
    );
}

export default QuitanceDeLoyerPage;
