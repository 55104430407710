import React from 'react';
import { useQuery } from 'react-query';
import { getRenters, getUsers } from '../../../utils/api/users';
import Icon from '../../Icon/Icon';

function ListLocataire() {
  const { data: user } = useQuery('profile');
  const {
    data: users,
    error,
    isLoadingUsers,
  } = useQuery('Renters', user?.role === 'Admin' ? getRenters : getUsers);

  if (users?.length > 0)
    return (
      <div className='card-data'>
        <div>
          <h3>Locataires Actuels</h3>
          <div
            className='card-data-body'
            style={{ maxHeight: 400, overflow: 'auto' }}
          >
            {users
              ?.filter((u) => u?.rentedProperties?.length > 0)
              ?.map((user, i) => (
                <div className='item' key={i}>
                  <div className='avatar'>
                    <img src={user?.IdentityUrls[0] || '/logo-64.png'} alt='' />
                    <div>
                      <h5>
                        {user?.first_name} {user?.last_name}
                      </h5>
                      <h6>{user?.email}</h6>
                    </div>
                    <div style={{ marginLeft: 'auto', display: 'flex' }}>
                      {user?.contractUrl && (
                        <a href={user?.contractUrl}>
                          <img className='pdf' src='/images/pdf.png' alt='' />
                        </a>
                      )}
                      <a href={`tel:${user?.phone}`}>
                        <button>Contact</button>
                      </a>
                    </div>
                  </div>

                  <div className='details'>
                    <div className='price'>
                      {user?.rentedProperties[0]?.price} MAD / <span>mois</span>
                    </div>

                    <div className='position'>
                      <Icon name='position' />
                      {user?.rentedProperties[0]?.address}
                    </div>
                    <div className='value'>
                      {/* {moment().diff(moment())} */}3 /{' '}
                      {user?.rentedProperties[0]?.durée}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
}

export default ListLocataire;
