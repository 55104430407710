import React from 'react';

function Icon({
  name = 'users',
  style = {},
  className = '',
  click = () => {},
}) {
  return (
    <svg
      onClick={click}
      height={'1em'}
      width={'1em'}
      style={style}
      className={className}
    >
      <use href={`/icons/icons-font.svg?v=4#${name}`}></use>
    </svg>
  );
}

export default Icon;
