import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import { createTw } from 'react-pdf-tailwind';
import { useQuery } from 'react-query';

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
});
const tw = createTw({
  theme: {
    fontFamily: {
      sans: ['italic'],
    },
    extend: {
      colors: {
        custom: 'cornflowerblue',
      },
    },
  },
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    color: 'white',
  },
  section: {
    margin: 10,
    padding: 10,
  },
  viewer: {
    backgroundColor: 'white',
    width: '1249px',
    height: '800px',
  },
});
const ContratDeBail = (data) => {
  const {
    propriétaire_name = '……………………………………………',
    properté_name = '……………………………………………',
    sis = '……………………………………………',
    locataire = '……………………………………………',
    adresse_du_locataire = '……………………………………………',
    locaux_et_équipements_privatifs = '……………………………………………',
    adresse_des_locaux = '……………………………………………',
    durée_en_chiffres_ans = '……………………………………………',
    durée_de = '……………………………………………',
    durée_à = '……………………………………………',
    montant_mad = '……………………………………………',
    montant_chiffres_mad = '……………………………………………',
    provision_sur_charge = '……………………………………………',
    total_mensuel = '……………………………………………',
    échéance_chaque_mois = '……………………………………………',
    garantie_mad = '……………………………………………',
    garantie_en_lettre_mad = '……………………………………………',
    fait_à = '……………………………………………',
    date = '……………………………………………',
    le = '……………………………………………',
    exemplaire = '……………………………………………',
    locataire_first_name = '……………………',
    locataire_last_name = '……………………',
  } = data;
  return (
    <Document>
      <Page size='A4' style={{ backgroundColor: '#FFFFFF', paddingTop: 40 }}>
        <View style={tw('bg-[#FFFFFF] w-full px-8 py-3  gap-4 border-b-2')}>
          <Text style={tw('text-[20.92px] font-normal text-black text-center')}>
            Contrat de bail
          </Text>
        </View>
        <View style={tw('mx-12 pt-5')}>
          <Text
            style={tw('text-[10.33px] font-normal text-black inline-flex ')}
          >
            Entre les soussignés :
          </Text>
          <Text>{'\n'}</Text>
          <Text style={tw('text-[10.33px] font-normal text-black ')}>
            M. ou Mme ………{propriétaire_name}……… , propriétaire du ………
            {properté_name}
            ..............
            <Text>{'\n'}</Text>
            sis……………{sis}……………
            <Text>{'\n'}</Text> ci-après dénommé(e) "Le Bailleur"
          </Text>
          <Text>{'\n'}</Text>
          <Text style={tw('text-[10.33px] font-normal text-black')}>et</Text>
          <Text>{'\n'}</Text>
          <Text style={tw('text-[10.33px] font-normal text-black inline-flex')}>
            M. ou Mme …………{locataire_first_name} {locataire_last_name}
            ……..demeurant (adresse)
            <Text>{'\n'}</Text>
            …………………………………………………………………………………………………………………………………………………..
            <Text>{'\n'}</Text>
            ci-après dénommé(e)(s) "Le Locataire"
          </Text>
          <Text>{'\n'}</Text>
          <Text style={tw('text-[10.33px] font-normal text-black ')}>
            IL A ETE CONVENU ET ARRETE CE QUI SUIT :<Text>{'\n'}</Text>
            Le Bailleur donne en location les locaux et équipements ci-après
            désignés au Locataire qui les accepte :<Text>{'\n'}</Text>- Les
            locaux et équipements privatifs suivants :<Text>{'\n'}</Text>
            ……………{locaux_et_équipements_privatifs}……………………………………..
            <Text>{'\n'}</Text>
            situé (e) à l'adresse suivante :<Text>{'\n'}</Text>
            …………………………………………{adresse_du_locataire}……………………………………………………..
            <Text>{'\n'}</Text>
            ainsi que, le cas échéant, les équipements désignés sur une liste
            annexée aux présentes.
            <Text>{'\n'}</Text>
            <Text>{'\n'}</Text>
            1. Durée du contrat
            <Text>{'\n'}</Text>
            Le présent contrat est conclu pour une durée de trois ans. Il
            prendra effet à compter du …{durée_de}.. Le présent bail se
            renouvellera cependant par tacite reconduction par période
            de…………………faute de préavis contraire donné par l'une des parties à
            l'autre sous forme de lettre recommandée trois mois avant
            l'expiration de la période en cours.
            <Text>{'\n'}</Text>
            <Text>{'\n'}</Text>
            2. Résiliation
            <Text>{'\n'}</Text>
            Il pourra être résilié par lettre recommandée avec avis de réception
            ou par acte d'huissier : <Text>{'\n'}</Text>-par Le Locataire, à
            tout moment, sous réserve de prévenir Le Bailleur trois mois à
            l'avance. <Text>{'\n'}</Text>-par Le Bailleur, au terme du contrat,
            en cas de motif sérieux et légitime résultant notamment de
            l'inexécution par Le Locataire de l'une des obligations lui
            incombant.
            <Text>{'\n'}</Text>
            <Text>{'\n'}</Text>
            3. Destination des lieux
            <Text>{'\n'}</Text>
            Les lieux loués, objet du présent contrat, sont destinés à l'usage
            exclusif d'habitation. Le Locataire ne peut, en conséquence, y
            exercer une quelconque profession qu'elle soit artisanale,
            commerciale ou libérale, sauf à solliciter et à obtenir
            l'autorisation expresse et écrite du Bailleur.
            <Text>{'\n'}</Text>
            <Text>{'\n'}</Text>
            4. Montant de la location
            <Text>{'\n'}</Text>
            <Text>
              La présente location est consentie et acceptée moyennant paiement
              d'un loyer mensuel librement fixé entre les parties.
              <Text>{'\n'}</Text> Le montant de la présente location s'établit
              comme suit :<Text>{'\n'}</Text>
              Somme en toutes lettres…………………………….{montant_mad}
              …………………………dirhams
              <Text>{'\n'}</Text> Somme en chiffres……{montant_chiffres_mad}
              ……………dirhams
              <Text>{'\n'}</Text>
              <Text>{'\n'}</Text>
            </Text>
            <View style={tw('flex justify-between')}>
              <Text>Loyer principal :</Text>
              <Text style={tw(' ml-4')}>..........dirhams</Text>
            </View>
            <Text>{'\n'}</Text>
            <View style={tw('flex justify-between')}>
              <Text>Provisions sur charges :</Text>
              <Text style={tw(' ml-4')}>
                ..{provision_sur_charge}.............dirhams
              </Text>
            </View>
            <Text>{'\n'}</Text>
            <View style={tw('flex justify-between')}>
              <Text>
                TOTAL MENSUEL.........{total_mensuel}..........dirhams
              </Text>
            </View>
            <Text>{'\n'}</Text>
            <View style={tw('flex justify-between')}>
              <Text>
                La somme, ci-dessus mentionnée, sera payable au plus tard le ...
                de chaque mois. Le présent loyer pourra être révisé à la demande
                de l'une ou l'autre des parties tous
                <Text>{'\n'}</Text>
                les……{durée_en_chiffres_ans}………………….ans.
              </Text>
            </View>
          </Text>
        </View>
      </Page>
      <Page size='A4' style={tw('bg-[#FFFFFF] ')}>
        <View style={tw('bg-[#FFFFFF] w-full px-8 py-3  gap-4 border-b-2')}>
          <Text style={tw('text-[20.92px] font-normal text-black text-center')}>
            Contrat de bail
          </Text>
        </View>
        <View style={tw('mx-12 pt-5')}>
          <Text style={tw('text-[10.33px] font-normal text-black inline-flex')}>
            Dans le cas où le présent loyer sera réglementé par des textes
            législatifs, les variations prévues par ces textes seront
            applicables de plein droit.
          </Text>

          <Text style={tw('text-[10.33px] font-normal text-black ')}>
            <Text>{'\n'}</Text>
            5. Dépôt de garantie
            <Text>{'\n'}</Text>
            Aux fins de garantir la bonne exécution de ses obligations, Le
            Locataire versera un dépôt de garantie. Le locataire verse ainsi ce
            jour au Bailleur, qui le reconnaît et lui en donne quittance, la
            somme de
            <Text>{'\n'}</Text>
            .....................................................................................................................dirhams
            (en lettres)
            <Text>{'\n'}</Text>
            (…………………….dirhams (en chiffres)).
            <Text>{'\n'}</Text>
            Le dépôt ainsi versé sera restitué au Locataire le jour de la
            restitution des clés, déduction faite, le cas échéant, des sommes
            restant dues au Bailleur.
            <Text>{'\n'}</Text>
            <Text>{'\n'}</Text>
            7. Obligations du Bailleur
            <Text>{'\n'}</Text>
            Le Bailleur est tenu des obligations principales suivantes :
            <Text>{'\n'}</Text>. délivrer au Locataire les lieux loués en bon
            état d'usage et de réparations, ainsi que les équipements existants
            en bon état de fonctionnement ;<Text>{'\n'}</Text>. assurer au
            Locataire la jouissance paisible des lieux loués et de le garantir
            des vices ou défauts de nature à y faire obstacle
            <Text>{'\n'}</Text>. entretenir les locaux en état de servir à
            l'usage prévu par le présent contrat et y faire toutes les
            réparations, autres que locatives, nécessaires au maintien en état
            et à l'entretien normal des lieux loués ;<Text>{'\n'}</Text>. ne pas
            s'opposer aux aménagements réalisés par Le Locataire, sous réserve
            qu'ils ne constituent pas une transformation de la chose louée.
            <Text>{'\n'}</Text>
            <Text>{'\n'}</Text>
            8. Obligations du Locataire
            <Text>{'\n'}</Text>
            Le Locataire est tenu des obligations principales suivantes :
            <Text>{'\n'}</Text>. payer le loyer et toute autre somme due aux
            termes convenus ;<Text>{'\n'}</Text>. user paisiblement des lieux
            loués suivant la destination qui leur a été donnée par le présent
            contrat ;<Text>{'\n'}</Text>. répondre des dégradations et pertes
            qui surviendraient pendant la durée du contrat dans les lieux loués
            dont il a la jouissance exclusive, à moins qu'il ne prouve qu'elles
            ont eu lieu par cas de faute majeure, par la faute du Bailleur ou
            par le fait d'un tiers qu'il n'a pas introduit dans les lieux loués
            ;<Text>{'\n'}</Text>. prendre à sa charge l'entretien courant des
            lieux loués ;<Text>{'\n'}</Text>. ne pas transformer les lieux loués
            sans l'accord écrit du Bailleur ; à défaut d'accord, Le Bailleur
            pourra exiger du Locataire, lors de son départ, la remise en état
            des lieux loués ou conserver à son bénéfice les transformations
            effectuées sans que le locataire puisse réclamer une quelconque
            indemnité ; Le Bailleur pourra toutefois exiger la remise immédiate
            des lieux en l'état, aux frais du Locataire, lorsque les
            transformations effectuées mettront en péril le bon fonctionnement
            des équipements ou la sécurité des lieux loués ;<Text>{'\n'}</Text>.
            Ne pas consentir des sous-locations totales ou partielles. Il est
            également interdit de céder tout ou partie de ses droits locatifs;
            <Text>{'\n'}</Text>
            <Text>{'\n'}</Text>
            9. Election de domicile
            <Text>{'\n'}</Text>
            Les parties signataires font élection de domicile : Le Bailleur en
            son domicile et Le Locataire dans les lieux loués.
            <Text>{'\n'}</Text>
            <Text>{'\n'}</Text>
            Fait à …..…………..., le ……………….
            <Text>{'\n'}</Text>
            En ………... exemplaires, dont un est remis à chacune des parties qui
            le reconnaît.
            <Text>{'\n'}</Text>
            <Text>{'\n'}</Text>
            LE BAILLEUR                                                         
            LE LOCATAIRE
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default ContratDeBail;
