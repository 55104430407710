import moment from "moment";

const timeCalc = (startDate,endDate) => {
  // Calculate the duration in minutes
  let durationMinutes = moment(endDate).diff(startDate, "minutes");

  // Calculate hours and minutes
  let hours = Math.floor(durationMinutes / 60);
  let minutes = durationMinutes % 60;

  // Create a string based on the conditions
  let durationString = " ";
  if (hours === 1) {
    durationString += "1 hour";
  } else if (hours > 1) {
    durationString += hours + " hours";
  }

  if (minutes > 0) {
    if (hours > 0) {
      durationString += " and ";
    }
    durationString += minutes + " minutes";
  }

  return durationString;
};

export {timeCalc}