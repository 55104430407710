import React from 'react';
import FormPropriete from './FormPropriete/FormPropriete';
import { addProperty } from '../../utils/api/properties';
import { useNavigate } from 'react-router-dom';
import { getOwner, getOwners } from '../../utils/api/users';
import { useQuery } from 'react-query';

function AddProperieteOwner() {
  const router = useNavigate();
  const { data: owner, error } = useQuery('owner', getOwner);

  const SaveProprety = (data) => {
    addProperty(data)
      ?.then((p) => {
        router('/proprietes');
      })
      .catch((err) => {});
  };
  if (owner) return <FormPropriete {...{ SaveProprety, owners: [owner] }} />;
}

export default AddProperieteOwner;
