import { Outlet } from 'react-router-dom';
import AppLayout from '../AppLayout';
import Staging from '../../screens/Staging/Staging';
import ShowStaging from '../../screens/Staging/ShowStaging';
import StagingList from '../../screens/Staging/StagingList/StagingList';

const stagingRouter = {
  path: '/staging',
  element: <Outlet />,
  children: [
    {
      path: 'ajouter',
      element: (
        <AppLayout>
          <Staging />
        </AppLayout>
      ),
    },
    {
      path: '',
      element: (
        <AppLayout>
          <StagingList />
        </AppLayout>
      ),
    },
    {
      path: ':id',
      element: <ShowStaging />,
    },
  ],
};

export { stagingRouter };
