import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  getOwners,
  getTechniciens,
  getUsers,
} from '../../../../utils/api/users';
import Select from '../../../../components/Inputs/Select';

function SelectTechnicien({
  register,
  errors,
  lable = 'Bénéficiaire',
  name = 'beneficiare',
  setValue = () => {},
  watch = () => {},
}) {
  const { data: Techniciens } = useQuery('techniciens', getTechniciens);
  useMemo(() => {
    const { first_name, last_name } =
      Techniciens?.find((user) => user?._id === watch(name)) || {};

    if (first_name) setValue('technicien_name', `${first_name} ${last_name}`);
  }, [watch(name)]);
  return (
    <Select
      {...{
        register,
        errors,
        lable,
        name,
      }}
    >
      <option value='' selected disabled>
        {name}
      </option>
      {Techniciens?.map((l, i) => (
        <option key={i} value={l?._id}>
          {l?.first_name} {l?.last_name}
        </option>
      ))}
    </Select>
  );
}

export default SelectTechnicien;
