import React from 'react';
import './app.css';
import { Toaster } from 'react-hot-toast';

import AppRouter from './layouts/RouterLayout';
import Popup from './components/Popup/Popup';

const App = () => {
  return (
    <>
      <Toaster position='top-right' />
      <AppRouter />
      <Popup />
    </>
  );
};

export default App;
