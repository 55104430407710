import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import './Propriete.css';
import Tab from '../../../components/Tab/Tab';

import { useNavigate, useParams } from 'react-router-dom';
import Apercu from '../../../components/Tab/Apercu';
import Details from '../../../components/Tab/Details';

import VisiteVirtuelle from '../../../components/Tab/VisiteVirtuelle';
import { useQuery } from 'react-query';
import { getProperty } from '../../../utils/api/properties';
import Maps from '../../../components/Maps/Maps';

function Propriete() {
  const { id } = useParams();
  const swiperRef = useRef();
  const { data: property, isLoading } = useQuery(`property/${id}`, () =>
    getProperty(id)
  );
  const { data: profile } = useQuery('profile');
  const [Index, setIndex] = useState(0);
  const tabs = ['Aperçu', 'Détails', 'Localisation', 'Visite virtuelle'];
  const router = useNavigate();

  if (isLoading) <>Loading...</>;

  return (
    <div className='Propriete show_logo_only'>
      <div className='swiper-container'>
        <Swiper
          autoplay={{ delay: 2000, disableOnInteraction: true }}
          modules={[Autoplay]}
          ref={swiperRef}
          onSlideChange={(e) => setIndex(e?.activeIndex || 0)}
        >
          {property &&
            property.images.map((image, i) => (
              <SwiperSlide key={i}>
                <img src={image} alt='' height={100} width={100} />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className='pagination-swiper'>
          {property &&
            property.images.map((image, i) => (
              <div className={`p-img ${i === Index ? 'active' : ''}`} key={i}>
                <img
                  src={image}
                  height={40}
                  width={40}
                  onClick={() => {
                    swiperRef.current?.swiper?.slideTo(i);
                  }}
                  alt=''
                />
              </div>
            ))}
        </div>
      </div>
      {property && (
        <div style={{ flex: 1 }}>
          <Tab
            tabs={tabs}
            components={[
              <Apercu property={property} />,
              <Details property={property} />,
              <Maps
                position={{
                  lat: property?.latitude || 0,
                  lng: property?.longitude || 0,
                }}
              />,
              <VisiteVirtuelle property={property} />,
            ]}
          />

          {profile?.role !== 'Locataire' && (
            <button
              className='add Propriete-button'
              onClick={() => router(`/proprietes/modifier/${property?._id}`)}
            >
              Modifier
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default Propriete;
