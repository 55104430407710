import React from 'react';
import Icon from '../Icon/Icon';
import Input from '../Inputs/Input';
import Upload from '../Upload/Upload';
import { useSearchParams } from 'react-router-dom';

function Détail_de_la_propriété({
  register,
  errors,
  watch,
  Onsubmit,
  setValue,
}) {
  const [params, setParams] = useSearchParams();
  const tab = Number(params.get('tab'));

  const handleDeleteImage = (index) => {
    setValue(
      'images',
      watch('images').filter((_, i) => i !== index)
    );
  };

  return (
    <div className='item-container'>
      <div className='container'>
        <h4>Réserver Solutions de Capture Numérique</h4>
        <p>
          Merci de fournir les informations suivantes pour nous permettre
          d'évaluer la disponibilité de notre service dans votre zone, le tarif
          de la numérisation et l'équipement que notre technicien devra
          apporter.
        </p>
        <h5>Localisation de la Propriété</h5>
        <form
          action=''
          id='form-capture'
          style={{ marginTop: 20 }}
          onSubmit={Onsubmit}
        >
          <Input
            {...{ lable: 'Pays', name: 'pays', register, errors, watch }}
          />
          <Input
            {...{ lable: 'Ville', name: 'ville', register, errors, watch }}
          />
          <Input
            {...{ lable: 'Adresse', name: 'address', register, errors, watch }}
          />
          <Input
            {...{ lable: 'Lien', name: 'lien', register, errors, watch }}
          />

          <div className='line'></div>
          <div className='input-container '>
            <label htmlFor=''>Détails de la numérisation</label>
            <span style={{ fontWeight: 300, fontSize: 12 }}>
              De quel type de propriété s’agit-il ?
            </span>
            <div className='check-box check-input'>
              <input
                {...register('numerisation')}
                type='radio'
                checked
                value={'Commerciale'}
              />
              <div className='out'>
                <Icon name='checkbox' />
              </div>
              Commerciale
            </div>
          </div>
          <div className='input-container '>
            <div className='check-box check-input'>
              <input
                {...register('numerisation')}
                type='radio'
                value={'Résidentielle'}
              />
              <div className='out'>
                <Icon name='checkbox' />
              </div>
              Résidentielle
            </div>
          </div>
          <div className='line'></div>
          <Input
            {...{
              errors,
              register,
              lable: 'Nom de l’espace à capturer',
              name: 'nom_capturer',
            }}
          />
          <Input
            {...{
              errors,
              register,
              lable: 'Superficie approcimative',
              name: 'superficie',
              type: 'number',
            }}
          />
        </form>
      </div>
      <div className='right'>
        {/* {watch('images')?.map((img,i)=> <img key={i}
          src={img}
          alt=''
        />)} */}
        <div className='flex flex-upload'>
          {(watch('images') || [])?.map((image, index) => (
            <div className='upload' key={index}>
              <img src={image} alt={`Image ${index + 1}`} />
              <button type='button' onClick={() => handleDeleteImage(index)}>
                <Icon name='close' />
              </button>
            </div>
          ))}

          <Upload
            onUpload={(url) =>
              setValue('images', [...(watch('images') || []), url])
            }
          />
        </div>
        <div className='input-container'>
          <span className='error'>
            {`${
              errors && errors?.images?.message ? errors?.images?.message : ''
            }`}
          </span>
        </div>
        {/* <button className='add white'>
          <Icon name='add-cercle' />
          Ajouter un Espace
        </button> */}
        <button
          style={{ marginTop: 20 }}
          className='add'
          type='submit'
          form='form-capture'
        >
          Suivant
        </button>
      </div>
    </div>
  );
}

export default Détail_de_la_propriété;
