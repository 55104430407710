import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  getOwners,
  getTechniciens,
  getUsers,
} from '../../../../utils/api/users';
import Select from '../../../../components/Inputs/Select';
import { getProperties } from '../../../../utils/api/properties';

function SelectProprieté({
  register,
  errors,
  profile,
  watch,
  setValue,
  lable,
  name,
}) {
  const { data: properties, isLoading } = useQuery('properties', getProperties);
  useMemo(() => {
    const { unité: properté_name, price } =
      properties?.find((o) => o?._id === watch(name)) || {};

    if (properté_name) setValue('properté_name', properté_name);
    if (price) setValue('montant', price);
  }, [watch(name)]);
  return (
    <Select
      {...{
        register,
        errors,
        lable,
        name,
      }}
    >
      <option value='' selected disabled>
        {name}
      </option>
      {properties?.map((p, i) => (
        <option key={i} value={p?._id} onSelect={() => console.log(value)}>
          {p?.unité}
        </option>
      ))}
    </Select>
  );
}

export default SelectProprieté;
