import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import moment from 'moment';
import { object } from 'zod';

function Facture(data) {
  const {
    properté_name = '',
    date = new Date(),
    échéance = new Date(),
    émetteur_name = '',
    user_name = '',
    type_de_contrat = '',
    description = '',
    intervention_réalisée = '',
    pièces_rempalcés = '',
    designation = [],
    total = 0,
    subtotal = 0,
    tva = 0,
    IF = '',
    ICE = '',
    RC = '',
    REMISE = 0,
    phone = '',
    email = '',
  } = data;
  console.log(data);
  function generateRandomNumber() {
    // Générer un nombre aléatoire entre 0 et 1, le multiplier par 9000 (pour avoir une plage de 0 à 8999),
    // puis ajouter 1000 pour obtenir une plage de 1000 à 9999
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    return randomNumber;
  }

  const [Id, setId] = useState(generateRandomNumber());

  Font.register({
    family: 'Outfit',
    src: '/fonts/Outfit-Regular.ttf',
  });

  Font.register({
    family: 'Outfit-Bold',
    src: '/fonts/Outfit-Bold.ttf',
  });

  const table = [
    ['Description', 'Qté', 'Prix', 'TVA 20%', 'Montant'],
    ...designation?.map((d) => Object.values(d)),
    // [
    //   "Location d'espace commercial-Unité 87632",
    //   '1',
    //   '5400.00',
    //   '0.00',
    //   '5400.00',
    // ],
    // ['Intervention & réparation', '1', '2300.00', '0.00', '2300.00'],
  ];

  return (
    <Document>
      <Page size={'A4'} style={{ fontFamily: 'Outfit' }}>
        <View style={{ padding: '40px 20px ' }}>
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
            }}
          >
            <Image source={'/images/Logo.png'} style={{ width: 280 }} />
            <Text
              style={{
                fontFamily: 'Outfit-Bold',
                fontSize: 20,
                fontWeight: 'bold',
              }}
            >
              Facture N°{Id}
            </Text>
          </View>
          <View
            style={{
              textAlign: 'right',
              marginTop: 14,
              fontWeight: 'medium',
              color: '#0A2540',
            }}
          >
            <Text
              style={{
                fontSize: 14,
              }}
            >
              VR BOOST AGENCY
            </Text>
            <View style={{ fontSize: 12, marginTop: 5 }}>
              <Text>RC: {RC}</Text>
              <Text>
                IF: {IF} / ICE: {ICE}
              </Text>
              <Text>
                {email} | {phone}
              </Text>
            </View>
          </View>
          <View
            style={{
              textAlign: 'right',
              marginTop: 14,
              fontWeight: 'medium',
              color: '#0A2540',
            }}
          >
            <Text
              style={{
                fontSize: 14,
              }}
            >
              {user_name}
            </Text>
            <View
              style={{
                fontSize: 12,
                marginTop: 5,
                display: 'flex',
                flexDirection: 'row',
                gap: 20,
                justifyContent: 'flex-end',
              }}
            >
              <View>
                <Text>NUMÉRO DE FACTURE:</Text>
                <Text>DATE DE LA FACTURE:</Text>
                <Text>ÉCHÉANCE:</Text>
              </View>
              <View style={{ display: 'flex', alignItems: 'flex-end' }}>
                <Text>Fact-{Id}</Text>
                <Text>{moment(date || '').format('DD MMM yyyy')}</Text>
                <Text>{moment(échéance || '').format('DD MMM yyyy')}</Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: '100%',
              marginTop: 40,
              justifyContent: 'space-between',
              fontSize: 12,
              borderRadius: 4,
              borderColor: '#DFE4EA',
              borderWidth: 1,
              borderStyle: 'solid',
            }}
          >
            {table?.map((t, i) => (
              <View
                key={i}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: 12,
                  fontWeight: 'medium',
                  borderBottomColor: '#DFE4EA',
                  borderBottomWidth: 1,
                  borderStyle: 'solid',
                }}
              >
                <Text style={{ flex: 1 }}>{t[0]}</Text>
                <Text
                  style={{
                    width: 70,

                    textAlign: 'center',
                  }}
                >
                  {t[1]}
                </Text>
                <Text
                  style={{
                    width: 70,

                    textAlign: 'center',
                  }}
                >
                  {t[2]}
                </Text>
                <Text
                  style={{
                    width: 70,

                    textAlign: 'center',
                  }}
                >
                  {t[3]}
                </Text>
                <Text
                  style={{
                    width: 70,

                    textAlign: 'center',
                  }}
                >
                  {t[4]}
                </Text>
              </View>
            ))}
          </View>
          <View
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: 'auto',
              fontWeight: 'medium',
              fontSize: 12,
              marginTop: 60,
              flexDirection: 'row',
              gap: 20,
              padding: 10,
              borderRadius: 5,
              borderColor: '#DFE4EA',
              borderWidth: 1,
              backgroundColor: '#F9F9FA',
            }}
          >
            <View>
              <Text style={{ padding: 6 }}>Sous Total (HT) :</Text>
              <Text style={{ padding: 6 }}>TVA (20%):</Text>
              <Text style={{ padding: 6 }}>Remise:</Text>
              <Text style={{ padding: 6, fontFamily: 'Outfit-Bold' }}>
                Montant:
              </Text>
            </View>
            <View style={{ display: 'flex', alignItems: 'flex-end' }}>
              <Text style={{ padding: 6 }}>{subtotal?.toFixed(2)}</Text>
              <Text style={{ padding: 6 }}>{tva?.toFixed(2)}</Text>
              <Text style={{ padding: 6 }}>
                {Number(REMISE || 0)?.toFixed(2)}
              </Text>
              <Text style={{ padding: 6, fontFamily: 'Outfit-Bold' }}>
                {(total - Number(REMISE || 0))?.toFixed(2)} MAD
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            width: '100%',
            height: 264,
            backgroundColor: '#F9F9FA',
            position: 'absolute',
            bottom: 0,
            left: 0,
            padding: '40px 20px 20px ',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            gap: 20,
          }}
        >
          <View>
            <Text style={{ color: '#635BFF', fontSize: 16, fontWeight: 700 }}>
              INSTRUCTIONS DE PAIEMENT
            </Text>
            <Text
              style={{
                color: '#0A2540',
                fontSize: 14,
                fontWeight: 700,
                margin: '10px 0',
              }}
            >
              VR Boost Agency
            </Text>

            <View
              style={{
                color: '#0A2540',
                fontSize: 14,
                fontWeight: 700,
                display: 'flex',
                gap: 5,
              }}
            >
              <Text>Nom de la banque: CIHBANK</Text>
              <Text>SWIFT/IBAN: 28736830 CIHMA</Text>
              <Text>RIB: 245 253 2435617289039876 54</Text>
              <Text style={{ color: '#0A2540', fontWeight: 400 }}>
                Veuillez utiliser Fact-0063 comme numéro de référence
              </Text>

              <Text style={{ fontSize: 12, marginTop: 30 }}>
                Pour toutes questions, veuillez nous contacter à
              </Text>
              <Text style={{ fontSize: 12 }}>Contact@Vrboost.ma</Text>
            </View>
          </View>
          <View>
            <Text>Payer en ligne</Text>
            <Text style={{ color: '#377DFF', fontWeight: 700, fontSize: 14 }}>
              https://buy.stripe.com/Vrboost
            </Text>
            <Image
              src='/images/qrcode.png'
              alt=''
              style={{ height: 100, width: 100, marginTop: 20 }}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
}

export default Facture;
