import React, { useState } from 'react';
import Icon from '../../components/Icon/Icon';
import './Avatar.css';
import RightUser from './RightUser/RightUser';
import { useQuery } from 'react-query';
import { getVisits } from '../../utils/api/visits';
import { Link } from 'react-router-dom';

function Avatar() {
  const [ShowAvatar, setShowAvatar] = useState(false);
  const [SelectedAvatar, setSelectedAvatar] = useState(null);

  const { data: Visits } = useQuery('visits', getVisits);

  return (
    <div className='Avatar'>
      <button type='button' className='add' onClick={() => setShowAvatar(true)}>
        <Icon name={'add-cercle'} /> Ajouter un client
      </button>

      <div className='avatars'>
        {Visits?.map(({ client, _id }, i) => (
          <div key={i} className='user'>
            <div className='about' onClick={() => setSelectedAvatar(client)}>
              <Icon name='dots' />
            </div>
            <img
              src={client?.profileImg || '/images/users/profile.jpeg'}
              alt=''
            />
            <div className='name'>{client?.nom}</div>
            <div className='status'>Nouveau client</div>
            <div className='line'></div>
            <div className='flex'>
              {client?.visits?.length > 0 && (
                <div>
                  <p>Rendez Vous</p>
                  <span>{client?.visits?.length}</span>
                </div>
              )}
              <Link to={`/avatar/${_id}/start`}>
                <Icon name='video' />
                Appeler
              </Link>
            </div>
          </div>
        ))}

        {Array(10)
          .fill(0)
          .map((item,i) => (
            <span className='user' key={i} style={{ opacity: 0 }}></span>
          ))}
      </div>
      {(ShowAvatar || SelectedAvatar) && (
        <RightUser {...{ setShowAvatar, SelectedAvatar, setSelectedAvatar }} />
      )}
    </div>
  );
}

export default Avatar;
