import React from 'react';
import './Counts.css';
import Icon from '../../Icon/Icon';
import { getDashboard } from '../../../utils/api/dashboard';
import { useQuery } from 'react-query';

function CountsTechnicien({}) {
  const { data: dashboard, isLoading } = useQuery('dashboard', getDashboard);

  if (isLoading)
    return (
      <div className='LoadingAnime'>
        <div className='counts'>
          {[1, 1, 1, 1].map((_, i) => (
            <div className='count' key={i}>
              <div className='icon anime'></div>
              <div className='data'>
                <h5 className='anime'></h5>
                <span className='anime'></span>
              </div>
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <div className='counts'>
      <div className='count'>
        <Icon name='home-check' />
        <div className='data'>
          <h5> Interventions effectuées</h5>
          <div className='flex'>
            {dashboard?.interventions}
            <span style={{ fontSize: 16, marginRight: 'auto' }}></span>
            <span className='success'>+23%</span>
          </div>
        </div>
      </div>
      <div className='count'>
        <Icon name='cart' />
        <div className='data'>
          <h5> Revenu</h5>
          <div className='flex'>
            {dashboard?.Revenu}MAD
            <span className='danger'>-2.7%</span>
          </div>
        </div>
      </div>
      <div className='count'>
        <Icon name='home' />
        <div className='data'>
          <h5> Total de propriétés</h5>
          <div className='flex'>
            {dashboard?.propriétés}
            <span></span>
          </div>
        </div>
      </div>
      <div className='count'>
        <Icon name='home' />
        <div className='data'>
          <h5>Demandes</h5>
          <div className='flex'>
            {dashboard?.demandes}
            <span></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CountsTechnicien;
