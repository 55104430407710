import { useEffect, useState } from 'react';

const useFilterDocs = (docs = []) => {
  const [DocsType, setDocsType] = useState({});
  const [Dossier, setDossier] = useState();

  useEffect(() => {
    const categoryMap = docs.reduce((acc, doc) => {
      if (!acc[doc?.documentType || '']) {
        acc[doc?.documentType || ''] = [];
      }
      acc[doc?.documentType || ''].push(doc);
      return acc;
    }, {});
    setDocsType(categoryMap);
  }, [docs]);

  return { DocsType, Dossier, setDossier };
};

export { useFilterDocs };
