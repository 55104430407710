import React from 'react';
import FormPropriete from './FormPropriete/FormPropriete';
import { addProperty, updateProperty } from '../../utils/api/properties';
import { useNavigate, useParams } from 'react-router-dom';
import { getOwner, getOwners } from '../../utils/api/users';
import { useQuery } from 'react-query';

function UpdateProperieteOwner() {
  const { id } = useParams();
  const router = useNavigate();
  const { data: owner, error } = useQuery('owner', getOwner);

  const SaveProprety = (data) => {
    updateProperty(data, id)
      ?.then((p) => {
        router('/proprietes');
      })
      .catch((err) => {});
  };

  if (owner) return <FormPropriete {...{ SaveProprety, owners: [owner] }} />;
}

export default UpdateProperieteOwner;
