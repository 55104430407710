import { Outlet } from 'react-router-dom';
import AppLayout from '../AppLayout';
import Users from '../../screens/Users/Users';
import AddUser from '../../screens/Users/AddUser';
import UpdateUser from '../../screens/Users/UpdateUser';

const usersRouter = {
  path: '/utilisateurs',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    {
      path: '',
      element: <Users />,
    },
    {
      path: 'ajouter',
      element: <AddUser />,
    },
    {
      path: ':action/:id',
      element: <UpdateUser />,
    },
  ],
};

const ownerRouter = {
  path: '/utilisateurs',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    {
      path: '',
      element: <Users />,
    },
    {
      path: 'ajouter',
      element: <AddUser />,
    },
    {
      path: ':action/:id',
      element: <UpdateUser />,
    },
  ],
};

export { usersRouter, ownerRouter };
