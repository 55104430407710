import React, { Suspense, lazy, useEffect, useState } from 'react';
import './Documents.css';
import Icon from '../../components/Icon/Icon';
import { Link, useParams } from 'react-router-dom';
import Table from '../../components/Table/Table';
import { useQuery } from 'react-query';
import {
  afficherDocument,
  affichertousDocuments,
} from '../../utils/api/documents';
import moment from 'moment';
import { useFilterDocs } from '../../utils/hooks/useFilterDocs';
import ShowDocument from './ShowDocument';

function Documents() {
  let users_imgs = [
    '/images/users/jessica-felicio-_cvwXhGqG-o-unsplash.png',
    '/images/users/aiony-haust-3TLl_97HNJo-unsplash.png',
    '/images/users/christopher-campbell-rDEOVtE7vOs-unsplash.png',
    '/images/users/christopher-campbell-rDEOVtE7vOs-unsplash-1.png',
  ];
  const documents_type = {
    contrat_de_bail: 'Contrat de bail',
    fiche_intervention_maintenance: "Fiche d'Intervention",
    payment: 'Quittance de Loyer',
    facture: 'Facture',
    cin: 'CIN',
  };

  const keys = ['documentType', 'user', 'createdAt', 'moyen', 'objet', 'size'];
  const header = [
    'Document',
    'Utilisateur',
    'Date',
    'Moyen',
    'Objet',
    'Taille de fichier',
  ];

  const { data: profile } = useQuery('profile');
  const { id } = useParams();

  const { data: documents, isLoading } = useQuery(
    'documents',
    affichertousDocuments
  );

  const { DocsType, Dossier, setDossier } = useFilterDocs(documents);

  return (
    <div className='Documents'>
      {id && <ShowDocument id={id} />}
      {profile?.role !== 'Technicien' && (
        <>
          <Link to={'/documents/create'}>
            <button className='add'>
              <Icon name='add-cercle' />
              Créer un document
            </button>
          </Link>
        </>
      )}
      <div className='folders'>
        {Object.keys(DocsType)?.map((d, i) =>
          documents_type[d] ? (
            <div
              key={i}
              className='folder'
              style={{
                border: `solid 1px ${Dossier === d ? '#000' : '#0000'}`,
              }}
              onClick={() => setDossier((ds) => (d === ds ? null : d))}
            >
              <div className='avatar'>
                <img src='/images/folder.svg' alt='' />
                <div>
                  <h5>{documents_type[d]} </h5>
                  <h6>{DocsType[d]?.length} fichiers </h6>
                </div>

                <button>
                  <Icon name='dots-v' />
                </button>
              </div>
              <div className='users'>
                {users_imgs?.map((item) => (
                  <div className='user' key={item}>
                    <img src={item} alt='' />
                  </div>
                ))}
                <div className='user'>
                  <div className='items'>+12</div>
                </div>
              </div>
            </div>
          ) : (
            <div key={i}></div>
          )
        )}
      </div>
      <div className='flex .full'>
        <h3 className='bold'>Téléchargement Récents</h3>
        <Link onClick={() => setDossier(null)}>Afficher tout</Link>
      </div>
      <Table
        show_filter={false}
        keys={header || []}
        values={Dossier ? DocsType[Dossier] : documents || []}
        list={Dossier ? DocsType[Dossier] : documents || []}
        isLoading={isLoading}
      >
        {({ List }) =>
          List?.map((v, i) => (
            <tr key={i}>
              {keys?.map((k, j) => {
                switch (k) {
                  case 'documentType':
                    return (
                      <td key={j}>
                        <div className='flex'>
                          <img
                            height={20}
                            width={20}
                            className='pdf'
                            src='/images/pdf.png'
                            alt=''
                          />
                          {documents_type[v?.documentType || '']}
                        </div>
                      </td>
                    );
                  case 'Action':
                    return;
                  case 'createdAt':
                    return (
                      <td key={j}>
                        {moment(v['createdAt'])?.format('DD-MM-YYYY')}
                      </td>
                    );
                  case 'size':
                    return <td key={j}>{v?.size} Kbs</td>;
                  case 'user':
                    return (
                      <td key={j}>
                        {v?.user?.first_name} {v?.user?.last_name}
                      </td>
                    );
                  default:
                    return <td key={j}>{v[k]} </td>;
                }
              })}

              <td style={{ padding: 0 }}>
                <div>
                  <Link
                    style={{
                      display: 'flex',
                      justifyContent: 'center',

                      padding: '10px 0',
                    }}
                    to={'/documents/' + v?._id}
                  >
                    <Icon name='eye' />
                  </Link>
                </div>
              </td>
            </tr>
          ))
        }
      </Table>
    </div>
  );
}

export default Documents;
