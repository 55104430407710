import React from 'react';
import Icon from '../../../components/Icon/Icon';
import { useForm } from 'react-hook-form';
import './AddPaiement.css';
import { zodResolver } from '@hookform/resolvers/zod';
import { PaymentSchema } from '../../../utils/zod_check';
import Select from '../../../components/Inputs/Select';
import Input from '../../../components/Inputs/Input';
import { ajouterPaiement } from '../../../utils/api/paiement';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import SelectTechnicien from './SelectUsers/SelectTechnicien';
import AddPaiementMin from './AddPaiementMin';

function AddPaiement() {
  const [params] = useSearchParams();
  const router = useNavigate();
  const { data: profile, error } = useQuery('profile');
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: zodResolver(PaymentSchema),
    defaultValues: {
      payment_method: 'card',
      beneficiare: params?.get('beneficiare'),
      montant: params?.get('montant'),
      object: params?.get('object'),
      unité: params?.get('unité') || '',
    },
  });

  const CreatePayment = (data) => {
    ajouterPaiement(data)
      .then((data) =>
        router(`/documents/quittance/${data?.paymentWithProperty?._id}`)
      )
      .catch((error) => {
        console.error('Error adding payment:', error);
      });
  };
  console.log(errors);
  return (
    <div className='Paiement full'>
      <div className='payment-data'>
        <form
          action=''
          id='payment-form'
          onSubmit={handleSubmit(CreatePayment)}
        >
          <AddPaiementMin {...{ register, errors, profile, watch, setValue }} />

          <div className='details'>
            {/* <div className='flex full '>
              <Input
                {...{
                  register,
                  errors,
                  lable: 'Prénom',
                  name: 'first_name',
                }}
              />
              <Input
                {...{
                  register,
                  errors,
                  lable: 'Nom',
                  name: 'last_name',
                }}
              />
            </div> */}
            <div className='card-info'>
              <div className='flex full'>
                <div className='check-box'>
                  <input
                    type='radio'
                    placeholder='Entrer votre email'
                    value='card'
                    {...register('payment_method', {})}
                  />
                  <div className='out'>
                    <Icon name='success' />
                  </div>
                  <p>
                    <span className='bold'> Payer par carte de crédit</span>
                  </p>
                </div>

                <div className='card-type'>
                  <img src='/images/visa.svg' />

                  <img src='/images/mastercard.svg' />
                </div>
              </div>

              <div className='card-type-details'>
                <div className='flex full '>
                  <Input
                    {...{
                      register,
                      errors,
                      lable: 'Nom sur la carte',
                      name: 'user_card_name',
                      // type: 'number',
                    }}
                  />
                  <Input
                    {...{
                      register,
                      errors,
                      lable: 'Exp (mm/aa)',
                      name: 'exp_date',
                      // type: 'number',
                      className: 'min',
                    }}
                  />
                </div>
                <div className='flex full '>
                  <Input
                    {...{
                      register,
                      errors,
                      lable: 'Numéro de carte',
                      name: 'card_number',
                      // type: 'number',
                    }}
                  />
                  <Input
                    {...{
                      register,
                      errors,
                      lable: 'CVV',
                      name: 'card_cvv',
                      className: 'min',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='card-info'>
              <div className='flex full'>
                <div className='check-box'>
                  <input
                    type='radio'
                    value='virement'
                    placeholder='Entrer votre email'
                    {...register('payment_method')}
                  />
                  <div className='out'>
                    <Icon name='success' />
                  </div>
                  <p>
                    <div className='bold'>Par virement</div>
                    Payer avec Apple Pay Utilisateurs illimités et données
                    individuelles illimitées.
                  </p>
                </div>
              </div>
            </div>
            <div className='card-info'>
              <div className='flex full'>
                <div className='check-box'>
                  <input
                    type='radio'
                    value='paypal'
                    placeholder='Entrer votre email'
                    {...register('payment_method')}
                  />
                  <div className='out'>
                    <Icon name='success' />
                  </div>
                  <p>
                    <div className='bold'>Par prélévement bancaire</div>
                    Payer avec PayPal Vous serez redirigé vers le site web de
                    PayPal après avoir soumis votre commande
                  </p>
                </div>
              </div>
            </div>
            <div className='flex lock'>
              <div>
                <Icon name='lock' />
              </div>
              Nous protégeons vos informations de paiement à l'aide d'un système
              de cryptage afin d'assurer une sécurité de niveau bancaire.
            </div>
            <img src='/images/cmi.png' />
          </div>
        </form>
        <div className='payment-details-container'>
          <div className='paymen-details'>
            <span>Vous payez</span>
            <h3>
              {watch('montant')} <span>MAD</span>
            </h3>
            {watch('object') === 'proprietaire' && (
              <div className='flex full'>
                <p>
                  <span className='bold'>Loyer</span> <br />
                  {watch('properté_name')}
                </p>
                <span>
                  {watch('montant')} <span>MAD</span>
                </span>
              </div>
            )}
            {watch('object') === 'technicien' && (
              <div className='flex full'>
                <p>
                  <span className='bold'> Fiches d’interventions</span> <br />
                  {/* <ul>
                    <li>
                      Fiche unité 66527 <span>100.00</span>
                    </li>
                    <li>
                      Fiche Unité 98HB <span>150.00</span>
                    </li>
                  </ul> */}
                </p>
                <span>
                  {watch('montant')} <span>MAD</span>
                </span>
              </div>
            )}
            <div className='flex full'>
              <p>
                <span className='bold'>Remise</span>
              </p>
              <span> 0.00</span>
            </div>
            <div className='line'></div>

            <div className='flex full'>
              <p>
                <span className='bold'>Total</span>
              </p>

              <span>{watch('montant')} MAD</span>
            </div>
          </div>
          <button className='add' type='submit' form='payment-form'>
            Payer
            <Icon name='next' />
          </button>
        </div>
      </div>
    </div>
  );
}

export default AddPaiement;
