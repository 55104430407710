import React from 'react';
import './LoadingAnime.css';
import '../Dashboard/Dashboard.css';
import '../../components/Header/Header.css';
import '../../components/SideBar/SideBar.css';
import '../../components/Search/Search.css';
import '../../components/Dashboard/Counts/Counts.css';
import '../../components/Dashboard/HomeCard/HomeCard.css';
import '../../components/Dashboard/Chart/Chart.css';

function LoadingAnime() {
  return (
    <div className='LoadingAnime'>
      <div className='app-container '>
        <div className='SideBar'>
          <div className='Logo'>
            <div className='img anime'></div>
          </div>
          <menu>
            <ul>
              <li className='anime'></li>
              <li className='anime'></li>
              <li className='anime'></li>
              <li className='anime'></li>
              <li className='anime'></li>
              <li className='anime'></li>
              <li className='anime'></li>
              <li className='anime'></li>
            </ul>
          </menu>
          <div className='close-menu'></div>
        </div>
        <div className='overlay' />
        <div className='app-contents'>
          <div className='Header'>
            <button className='menu-button anime'></button>
            <div className='Search anime'></div>
            <div className='header-right'>
              <button className='anime Notifications'></button>
              <button className='anime Notifications'></button>
              <button className='user anime'></button>
            </div>
          </div>
          <div className='app-body'>
            <div className='Dashboard'>
              <h2 className='anime'></h2>
              <h6 className='anime'></h6>
              <div className='counts'>
                {[1, 1, 1, 1].map((_, i) => (
                  <div className='count' key={i}>
                    <div className='icon anime'></div>
                    <div className='data'>
                      <h5 className='anime'></h5>
                      <span className='anime'></span>
                    </div>
                  </div>
                ))}
              </div>
              <div className='grid-dash'>
                <div className='chart'>
                  <div className='flex'>
                    <span className='anime'></span>
                    <div className='values'>
                      <div>
                        <h5 className='anime'></h5>
                      </div>
                    </div>
                  </div>
                  <div className='chart-container anime'></div>
                  <p className='anime'> </p>
                </div>
                <a>
                  <div className='card-2'>
                    <div className='HomeCard'>
                      <div className='img anime'></div>
                      <div className='card-body'>
                        <div className='flex'>
                          <p className='price anime'></p>
                          <button
                            className='anime'
                            style={{ width: 100, height: 40 }}
                          ></button>
                        </div>
                        <div>
                          <h5 className='anime'> </h5>
                        </div>
                        <div className='line' />
                        <div className='flex flex-last'>
                          <div className='item anime'></div>
                          <div className='item anime'></div>
                          <div className='item anime'></div>
                          <div className='item anime'></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingAnime;
