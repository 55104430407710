import { Outlet } from 'react-router-dom';
import AppLayout from '../AppLayout';
import Paiement from '../../screens/Paiement/Paiement';
import AddPaiement from '../../screens/Paiement/AddPaiement/AddPaiement';
import PaiementDetails from '../../screens/Paiement/PaiementDetails/PaiementDetails';

const paiementsRouter = {
  path: '/paiements',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    { path: '', element: <Paiement /> },
    { path: 'add', element: <AddPaiement /> },
    { path: ':id', element: <PaiementDetails /> },
  ],
};

export { paiementsRouter };
