import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import './CallNavigation.css';

function CallLayout({ children }) {
  const { id } = useParams();
  return (
    <div className='Call-Navigation'>
      <div className='navigation'>
        <NavLink to={`/avatar/${id}/start`}>Lancer un appel</NavLink>
        <NavLink to={`/avatar/${id}/calendar`}>Calendrier</NavLink>
        <NavLink to={`/avatar/${id}/data`}>Data</NavLink>
      </div>
      {children}
    </div>
  );
}

export default CallLayout;
