import React, { useState } from 'react';
import Icon from '../../Icon/Icon';
import { useForm } from 'react-hook-form';
import SelectProprieté from '../../../screens/Paiement/AddPaiement/SelectUsers/SelectProprieté';
import { useQuery } from 'react-query';
import SelectUser from '../../../screens/Paiement/AddPaiement/SelectUsers/SelectUser';
import Designation from './Designation';
import Input from '../../Inputs/Input';

function FactureForm({ setformData = () => {} }) {
  let defaultValues = {};

  const { data: profile } = useQuery('profile');
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  return (
    <form
      className='mx-9 my-4'
      onSubmit={handleSubmit((data) => {
        console.log(data);
        setformData(data);
      })}
      // onChange={() => setformData(getValues())}
    >
      <Input
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: 'Object',
          name: 'object',
        }}
      />
      <SelectProprieté
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: 'Unité',
          name: 'unité',
        }}
      />
      <div className='input-container'>
        <label htmlFor='email'>Date</label>
        <input {...register('date')} type='date' />
      </div>
      <div className='input-container'>
        <label htmlFor='email'>échéance</label>
        <input {...register('échéance')} type='date' />
      </div>
      <SelectUser
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: 'Locataire',
          name: 'locataire',
        }}
      />
      <Designation {...{ setValue, watch }} />
      <Input
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: 'ICE',
          name: 'ICE',
        }}
      />
      <Input
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: 'IF',
          name: 'IF',
        }}
      />
      <Input
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: 'RC',
          name: 'RC',
        }}
      />
      <Input
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: 'Numero de Téléphone',
          name: 'phone',
        }}
      />
      <Input
        {...{
          register,
          errors,
          profile,
          watch,
          setValue,
          lable: 'Email',
          name: 'email',
        }}
      />
      <div className='input-container'>
        <input {...register('REMISE')} type='number' placeholder='REMISE' />
        <span className='error'></span>
      </div>
      <button className='add full ' style={{ maxWidth: '100%' }}>
        <Icon name='loading' />
        Actualiser
      </button>
    </form>
  );
}

export default FactureForm;
