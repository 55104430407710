import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  getOwners,
  getTechniciens,
  getUsers,
} from '../../../../utils/api/users';
import Select from '../../../../components/Inputs/Select';

function SelectProprietaire({
  register,
  errors,
  profile,
  watch,
  setValue,
  lable,
  name,
}) {
  const { data: Owners } = useQuery('oweners', getOwners);
  useMemo(() => {
    const { first_name, last_name } =
      Owners?.find((o) => o?.user?._id === watch(name))?.user || {};
    if (first_name) setValue('propriétaire_name', `${first_name} ${last_name}`);
  }, [watch(name)]);

  return (
    <Select
      {...{
        register,
        errors,
        lable,
        name,
      }}
    >
      <option selected disabled></option>
      {Owners?.map((l, i) => (
        <option key={i} value={l?.user?._id}>
          {l?.user?.first_name} {l?.user?.last_name}
        </option>
      ))}
    </Select>
  );
}

export default SelectProprietaire;
