import React, { Fragment, useEffect, useState } from 'react';
import './Dashboard.css';
import Icon from '../../components/Icon/Icon';
import Counts from '../../components/Dashboard/Counts/Counts';
import Chart from '../../components/Dashboard/Chart/Chart';
import HomeCard2 from '../../components/Dashboard/HomeCard/HomeCard2';
import { useQuery, useQueryClient } from 'react-query';
import { getProperties } from '../../utils/api/properties';
import { getDashboard } from '../../utils/api/dashboard';
import ListLocataire from '../../components/Dashboard/ListLocataires/ListLocataire';
import { Link } from 'react-router-dom';
function Dashboard() {
  const { data: user } = useQuery('profile');
  const { data: properties, isLoading } = useQuery('properties', getProperties);
  const { data: dashboard, isLoading: isLoadingDash } = useQuery(
    'dashboard',
    getDashboard
  );

  return (
    <div className='Dashboard '>
      <h2>
        Bonjour <span>{user?.first_name}</span>, bon retour !
      </h2>
      <h6>Voici quelques informations importantes pour vous :</h6>
      <Counts dashboard={dashboard} isLoading={isLoadingDash} />
      <div className='grid-dash'>
        <Chart />
        <HomeCard2
          isLoading={isLoading}
          propriete={properties ? properties[0] : {}}
        />

        <ListLocataire />

        <div className='card-data' style={{ overflow: 'auto' }}>
          {/* <div>
            <h3>
              Commentaires Récents <img src='/images/airbnb.png' alt='' />
            </h3>
            <div className='card-data-body'>
              {[1, 2].map((i) => (
                <div className='item' key={i}>
                  <div className='details'>
                    <div className='avatar'>
                      <img src='/images/users/g.jpeg' alt='' />
                      <h5 style={{ lineHeight: '32px' }}>Micheal Gough</h5>
                      <div className='stars'>
                        <Icon name='star' />
                        <Icon name='star' />
                        <Icon name='star' />
                        <Icon name='star' />
                        <Icon name='star' />
                      </div>
                    </div>
                    <p className='position'>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
          <div>
            <h3>Urgents</h3>
            <div className='card-data-body'>
              {dashboard?.urgences?.map((u, i) =>
                u?.unité?.unité && u?.step < 5 ? (
                  <Link to={`/documents/${u?._id}`} className='item' key={i}>
                    <div className='details danger'>
                      <div className='avatar '>
                        <Icon name='home-check' />
                        <h5 style={{ lineHeight: '32px' }}>
                          {u?.unité?.unité}
                        </h5>
                        <p className='position'>1 Fiches d’intervention</p>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Fragment key={i} />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
