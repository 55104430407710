import React, { useState } from "react";
import "./RightUser.css";
import Header from "../../../components/Header/Header";
import Icon from "../../../components/Icon/Icon";
import Upload from "../../../components/Upload/Upload";
import { useForm } from "react-hook-form";
import { createVisit } from "../../../utils/api/visits";
import { useQuery } from "react-query";
import SelectProprieté from "../../Paiement/AddPaiement/SelectUsers/SelectProprieté";

function RightUser({ setShowAvatar, SelectedAvatar, setSelectedAvatar }) {
  const [ShowEdit, setShowEdit] = useState(true);
  const { data: user } = useQuery("profile");
  const { register, handleSubmit, setValue, watch } = useForm({
    values: {
      client: { host: user?._id },
    },
  });

  const addVisit = (data) => {
    console.log({data})
    if (ShowEdit)
      createVisit({ data })
        .then((data) => {
          setShowAvatar(false);
          setShowEdit((e) => !e);
        })
        .catch((err) => {});
    else return setShowEdit((e) => !e);
  };

  return (
    <div className="RightUser">
      <div className="overlay" onClick={() => setShowAvatar(false)}></div>
      <Header />
      <form onSubmit={handleSubmit(addVisit)} className="profile">
        <h2>
          Profile du client{" "}
          <button
            type="button"
            onClick={() => {
              setShowAvatar(false);
              setSelectedAvatar(null);
            }}
          >
            <Icon name="close" />
          </button>
        </h2>
        {!SelectedAvatar ? (
          <div>
            <Upload
              onUpload={(url) => setValue("client.profileImg", url)}
              src={watch("client.profileImg")}
            />

            <div className="inputs-label">
              <div className="input-container">
                <label htmlFor="">Nom et Prénom</label>
                <input
                  type="text"
                  {...register("client.nom")}
                  placeholder="tapez votre nom et prénom"
                />
              </div>
              {/* <div className='input-container'>
                <label htmlFor=''>Prénom</label>
                <input
                  type='text'
                  {...register('client.prénom')}
                  placeholder='tapez votre prénom'
                />
              </div> */}
              <div className="input-container">
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  {...register("client.email")}
                  placeholder="tapez votre email"
                />
              </div>
              <div className="input-container">
                <label htmlFor="">Téléphone</label>
                <input
                  type="text"
                  {...register("client.telephone")}
                  placeholder="tapez votre téléphone"
                />
              </div>
              <div className="input-container">
                <label htmlFor="">Message</label>
                <input
                  type="text"
                  {...register("message")}
                  placeholder="tapez votre message"
                />
              </div>
              <div className="input-container">
                <label htmlFor="">Lien</label>
                <input
                  type="text"
                  {...register("lienVR")}
                  placeholder="tapez le lien de la visite virtuelle"
                />
              </div>
              <SelectProprieté
                {...{
                  register,
                  errors:null,
                  profile:user,
                  watch,
                  setValue,
                  lable:'Proprieté',
                  name:'unité',
                }}
              />
              <div className="input-container">
                <label htmlFor="">Date Visite </label>
                <input
                  type="date"
                  {...register("dateVisite")}
                  placeholder="tapez le date de la visite virtuelle"
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <img
              src={SelectedAvatar?.profileImg || "/images/users/profile.jpeg"}
              alt=""
            />
            <h3>{SelectedAvatar?.nom}</h3>
            <div className="line"></div>
            <h2>{SelectedAvatar?.email}</h2>
            <p className="info">
              <Icon name="email" />
              {SelectedAvatar?.email}
            </p>
            <div className="line"></div>
            <p className="info">
              <Icon name="mobile" />
              {SelectedAvatar?.telephone}
            </p>
            <div className="line"></div>
            <p className="info">
              <Icon name="home-position" />
              {SelectedAvatar?.email}
            </p>
          </div>
        )}

        <div className="last-meet">
          <div className="video">
            <Icon name="video" />
          </div>
          <div>
            <p>Dernière Visite virtuelle</p>
            <span>24-06-2024</span>
          </div>
        </div>
        <button className="add">{ShowEdit ? "Enregistrer" : "Modifier"}</button>
      </form>
    </div>
  );
}

export default RightUser;
