import React, { useMemo, useState } from 'react';
import Icon from '../../Icon/Icon';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getOwner, getOwners } from '../../../utils/api/users';
import SelectProprietaire from '../../../screens/Paiement/AddPaiement/SelectUsers/SelectProprietaire';
import SelectProprieté from '../../../screens/Paiement/AddPaiement/SelectUsers/SelectProprieté';
import { zodResolver } from '@hookform/resolvers/zod';
import { ContraDeBailSchema } from '../../../utils/zod_check';
import Input from '../../Inputs/Input';

function ContratDeBailForm({ setformData = () => {} }) {
  let defaultValues = {
    propriétaire: '',
    unité: '',
    sis: '',
    locataire: '',
    adresse_du_locataire: '',
    locaux_et_équipements_privatifs: '',
    adresse_des_locaux: '',
    durée_en_chiffres_ans: '',
    durée_de: '',
    durée_à: '',
    montant_mad: '',
    montant_chiffres_mad: '',
    provision_sur_charge: '',
    total_mensuel: '',
    échéance_chaque_mois: '',
    garantie_mad: '',
    garantie_en_lettre_mad: '',
    fait_à: '',
    date: '',
    le: '',
    exemplaire: '',
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: zodResolver(ContraDeBailSchema),
  });

  const SendData = (data) => {
    if (profile?.role === 'Propriétaire') {
      data.propriétaire = owner?.user?._id;
      const { first_name, last_name } = owner?.user;
      data.propriétaire_name = `${first_name} ${last_name}`;
    }
    setformData(data);
  };

  const { data: profile } = useQuery('profile');
  const { data: owner, error } = useQuery(
    `owner/${profile?._id}`,
    profile?.role === 'Propriétaire' ? getOwner : () => {}
  );
  if (profile)
    return (
      <form
        className='mx-9 my-4'
        onSubmit={handleSubmit(SendData)}
        // onChange={() => SendData(getValues())}
        id='form'
      >
        {profile?.role !== 'Propriétaire' && (
          <SelectProprietaire
            {...{
              register,
              errors,
              profile,
              watch,
              setValue,
              lable: 'Propriétaire',
              name: 'propriétaire',
            }}
          />
        )}

        <SelectProprieté
          {...{
            register,
            errors,
            profile,
            watch,
            setValue,
            lable: 'Unité',
            name: 'unité',
          }}
        />

        <div className='input-container'>
          <label htmlFor='email'>Sis</label>
          <input {...register('sis')} type='text' placeholder='05' />
          <span className='error'></span>
        </div>

        <Input
          {...{
            register,
            errors,
            watch,
            setValue,
            lable: 'Prénom de Locataire',
            name: 'locataire_first_name',
          }}
        />
        <Input
          {...{
            register,
            errors,
            watch,
            setValue,
            lable: 'Nom de Locataire',
            name: 'locataire_last_name',
          }}
        />
        <Input
          {...{
            register,
            errors,
            watch,
            setValue,
            lable: 'Adresse Email du locataire',
            name: 'adresse_du_locataire',
          }}
        />

        <div className='input-container'>
          <label htmlFor='email'>Locaux et équipements privatifs</label>
          <input
            {...register('locaux_et_équipements_privatifs')}
            type='text'
            placeholder='rachid.moufakkir@gmail.com'
          />
        </div>
        <div className='input-container'>
          <label htmlFor='email'>Durée en chiffres (Ans)</label>
          <input
            {...register('durée_en_chiffres_ans')}
            type='text'
            placeholder='Trois'
          />
        </div>
        <div className='input-container'>
          <label htmlFor='email'>Durée</label>
          <div className='flex-date'>
            <span>De</span>
            <input {...register('durée_de')} type='date' placeholder='Trois' />

            <span>à</span>
            <input {...register('durée_à')} type='date' placeholder='Trois' />
          </div>
        </div>
        <div className='flex full'>
          <div className='input-container'>
            <label htmlFor='email'>Montant ( MAD)</label>
            <input
              {...register('montant_mad')}
              type='text'
              placeholder='9800'
            />
          </div>
          <div className='input-container'>
            <label htmlFor='email'>Montant (chiffres MAD)</label>
            <input
              {...register('montant_chiffres_mad')}
              type='number'
              placeholder='Neuf mille huit cents Dirhams'
            />
          </div>
        </div>
        <div className='input-container'>
          <label htmlFor='email'>Provision sur charge</label>
          <input
            {...register('provision_sur_charge')}
            type='number'
            placeholder='300'
          />
        </div>
        <div className='input-container'>
          <label htmlFor='email'>Total mensuel</label>
          <input
            {...register('total_mensuel')}
            type='number'
            placeholder='300'
          />
        </div>
        <button className='add full ' style={{ maxWidth: '100%' }}>
          <Icon name='loading' />
          Actualiser
        </button>
      </form>
    );
}

export default ContratDeBailForm;
