import React from 'react';
import Icon from '../../components/Icon/Icon';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getCapture, updateCapture } from '../../utils/api/capture';
import moment from 'moment';
import { useForm } from 'react-hook-form';

function Demande() {
  const { id } = useParams();
  const router = useNavigate();
  const { data: capture } = useQuery('capture', () => getCapture(id));
  const UpdateCapture = (data) => {
    updateCapture(id, data)
      .then((data) => router('/rendez-vous/demandes/'))
      .catch((err) => console.log(err));
  };
  const { handleSubmit } = useForm();

  return (
    <div className='Demande-Page'>
      <div className='Demands-container'>
        <div className='Demande'>
          <h3>
            <Icon name='info' /> Le{' '}
            {moment(capture?.date).format('dddd DD MMMM ')} à {capture?.time}{' '}
            (GMT+)
          </h3>
          <p>
            Demande de visite virtuelle<span>-{capture?.name}</span>
          </p>
          <div className='form'>
            <img src={capture?.images[0]} />
            <form action='' onSubmit={handleSubmit(UpdateCapture)}>
              <div className='input-container'>
                <label htmlFor=''>Demande pour</label>
                <select name='' id=''>
                  <option value=''>Visite virtuelle</option>
                </select>
              </div>
              <div className='input-container'>
                <label htmlFor=''>Unité</label>
                <select name='' id=''>
                  <option value=''>{capture?.address}</option>
                </select>
              </div>

              <div className='input-container'>
                <label htmlFor=''>Date Souhaitée</label>
                <input
                  type='date'
                  value={moment(capture?.date).format('YYYY-MM-DD')}
                  name=''
                  id=''
                />
              </div>

              <div className='input-container'>
                <label htmlFor=''>Demandé par</label>
                <select name='' id=''>
                  <option value=''>{capture?.name}</option>
                </select>
              </div>

              <div className='input-container'>
                <label htmlFor=''>Date de visite</label>
                <input
                  type='date'
                  value={moment(capture?.date).format('YYYY-MM-DD')}
                  name=''
                  id=''
                />
              </div>
              <div className='flex full'>
                <button
                  type='button'
                  onClick={() => UpdateCapture({ annuler: true })}
                  className='add white'
                >
                  Annuler
                </button>
                <button className='add'>Accepter</button>
              </div>
            </form>
          </div>
          <div className='nb'>
            <Icon name='nb' />
            <p>
              NB: Lorsque vous acceptez ou annulez une demande de visite
              virtuelle, une notification sera automatiquement envoyée au client
              pour l'informer de votre décision. Veuillez noter que cette action
              est irréversible. Merci de vérifier soigneusement les détails de
              la réservation avant de procéder pour assurer l'exactitude de
              votre action.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Demande;
