import React, { useRef, useState } from 'react';
import './OTP.css';
import { Link } from 'react-router-dom';
import Icon from '../../../components/Icon/Icon';
import toast from 'react-hot-toast';
import { queryClient } from '../../../layouts/ReactQueryLayout';
import { useQueries, useQuery } from 'react-query';
import { reSendOTP, sendOTP } from '../../../utils/api/otp';
import { logout } from '../../../utils/api/auth';

function OTP() {
  const { data: user } = useQuery('profile');
  const [ShowSuccess, setShowSuccess] = useState(false);
  const SendOTP = (data) => {
    if (data?.length < 5) return;
    sendOTP({ otp: data, _id: user?._id })
      .then(({ user }) => {
        if (user) setShowSuccess(true);
      })
      .catch((err) => {
        console.log(err?.response?.data?.error);
      });
  };

  return (
    <div className='OTP'>
      <img src={'/images/Logo.svg'} className='Logo' alt='' />
      {!ShowSuccess ? (
        <>
          <img src={'/images/Wave.svg'} alt='' />
          <div className='info'>
            <h2>Entrer l’OTP</h2>
            <p>
              Entrez le code OTP que nous venons de vous envoyer sur votre
              adresse e-mail/numéro de téléphone enregistré
            </p>
          </div>

          <OtpInputWithValidation SendOTP={SendOTP} numberOfDigits={5} />

          <h4 style={{ textAlign: 'center' }}>
            Vous n'avez pas reçu l'OTP ?{' '}
            <Link
              className='primary-color'
              to={'/'}
              onClick={() => reSendOTP({ email: user?.email })}
            >
              Renvoyer l'OTP
            </Link>
            <br />
            ou{' '}
            <Link
              className='primary-color'
              to={'/'}
              onClick={() => {
                logout();
                queryClient.invalidateQueries('token');
                queryClient.invalidateQueries('profile');
              }}
            >
              Login
            </Link>
          </h4>
        </>
      ) : (
        <>
          <div className='success'>
            <h2>Succès</h2>
            <Icon name='success' />
          </div>
          <div style={{ height: 100 }}></div>
        </>
      )}
    </div>
  );
}

export default OTP;

function OtpInputWithValidation({ numberOfDigits, SendOTP }) {
  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(''));
  const [otpError, setOtpError] = useState(null);
  const otpBoxReference = useRef([]);

  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);

    if (value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === 'Backspace' && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === 'Enter' && e.target.value && index < numberOfDigits - 1) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  return (
    <>
      <div className='input'>
        {' '}
        {otp?.map((digit, index) => (
          <input
            key={index}
            value={digit}
            maxLength={1}
            inputmode='numeric'
            onChange={(e) => handleChange(e.target.value, index)}
            onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
            ref={(reference) => (otpBoxReference.current[index] = reference)}
          />
        ))}
      </div>

      <button
        disabled={!otp?.every((v) => v)}
        onClick={() => SendOTP(otp.join(''))}
      >
        Se connecter
      </button>
    </>
  );
}
