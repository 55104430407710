import React from 'react';
import './Header.css';
import Icon from '../Icon/Icon';

import { NavLink } from 'react-router-dom';
import Search from '../Search/Search';
import Notifications from '../Notifications/Notifications';
import ProfileHeader from '../ProfileHeader/ProfileHeader';
import { getRoleRoutes } from '../../utils/getRoleRoutes';
import { useQueries, useQuery } from 'react-query';
import { logout } from '../../utils/api/auth';

function Header() {
  const { data: user } = useQuery('profile');
  const { router } = getRoleRoutes(user);

  return (
    <div className='Header'>
      <button
        className='menu-button'
        onClick={() => {
          document.querySelector('.SideBar').classList.toggle('active');
          document.querySelector('.menu-button').classList.toggle('active');
        }}
      >
        <div className='bar'></div>
        <div className='bar'></div>
        <div className='bar'></div>
      </button>
      {/* <Search /> */}
      <div></div>
      <div className='header-right'>
        {/* <button className='Email'>
          <Icon name='mail' />
        </button> */}

        <Notifications />
        <ProfileHeader />
      </div>
      <div className='navigation-bar'>
        <NavLink to={router[1]?.link}>
          <Icon name={router[1]?.icon} />
        </NavLink>
        <NavLink to={router[2]?.link || '/'}>
          <Icon name={router[2]?.icon} />
        </NavLink>
        <div className='key-container'>
          <NavLink to={'/'} className='key'>
            <Icon name='grid' />
          </NavLink>
        </div>
        <Notifications />
        {/* <button>
          <Icon name='mail' />
        </button> */}
        {router?.length > 3 ? (
          <NavLink to={router[3]?.link || '/'}>
            <Icon name={router[3]?.icon} />
          </NavLink>
        ) : (
          <button onClick={logout}>
            <Icon name='log-out' />
          </button>
        )}
      </div>
    </div>
  );
}

export default Header;
