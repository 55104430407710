import React, { useState } from 'react';
import Icon from '../Icon/Icon';
import './Notifications.css';
import { useQuery } from 'react-query';
import { confirmNotification, deleteNotification } from '../../utils/api/users';

function Notifications() {
  const [Show, setShow] = useState(false);
  const { data: profile } = useQuery('profile');

  const notification =
    profile?.notification?.length > 0
      ? [...profile?.notification]?.reverse()
      : null;

  return (
    <div
      className={`Notifications ${notification?.length > 0 ? 'active' : ''}`}
    >
      <button onClick={() => setShow(true)}>
        <Icon name='notification' />
      </button>
      {Show && (
        <>
          <div className='overlay' onClick={() => setShow(false)}></div>
          <div className='list'>
            {notification ? (
              notification?.map((p, i) => (
                <div className='item' key={i}>
                  <div>
                    {p?.title}
                    <p>{p?.message}</p>
                    {p?.type === 'Payment' && p?.status === 'created' && (
                      <div className='flex'>
                        <button
                          className='add'
                          onClick={() =>
                            confirmNotification(p?.operationId, {
                              status: 'success',
                            })
                              .then((d) =>
                                deleteNotification(
                                  p?.operationId || p?._id
                                ).catch((err) => console.log(err))
                              )
                              .catch((err) => {
                                console.log(err);
                              })
                          }
                        >
                          <Icon name='edit' /> Confirmer
                        </button>
                        <button
                          className='add white'
                          onClick={() =>
                            deleteNotification(p?.operationId || p?._id).catch(
                              (err) => console.log(err)
                            )
                          }
                        >
                          <Icon name='trash' /> Supprimer
                        </button>
                      </div>
                    )}
                  </div>
                  <button
                    onClick={() =>
                      deleteNotification(p?.operationId || p?._id).catch(
                        (err) => console.log(err)
                      )
                    }
                  >
                    <Icon name='close' />
                  </button>
                </div>
              ))
            ) : (
              <div className='item'>
                <div>
                  Notifications
                  <p>Tu n'as aucune notification.</p>
                </div>
                <button onClick={() => setShow(false)}>
                  <Icon name='close' />
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Notifications;
