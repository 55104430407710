import { queryClient } from '../../layouts/ReactQueryLayout';
import { Axios } from './axios';

const sendOTP = async (data) => {
  return Axios.post('/auth/verify-otp', data).then(({ data }) => {
    const { token, verifiedUser } = data;

    if (verifiedUser && token) {
      localStorage.setItem('token', token);
      setTimeout(() => {
        queryClient.invalidateQueries('token');
        queryClient.invalidateQueries('profile');
      }, 1000);
      return { user: verifiedUser, token };
    }
  });
};

const reSendOTP = async (data) => {
  return Axios.post('/auth/resend-otp', data)
    .then(({ data }) => {})
    .catch((err) => console.log(err));
};

export { sendOTP, reSendOTP };
