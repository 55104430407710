import React from 'react';
import Icon from '../Icon/Icon';

function Details({ property }) {
  return (
    <div className='Apercu'>
      <h4>Propriétaire / manager</h4>
      <div className='flex'>
        <button>
          <Icon name='user' />
          {property?.owner?.user?.first_name} {property?.owner?.user?.last_name}
        </button>
        <a href={`tel:${property?.owner?.user?.phone}`}>
          <button>
            <Icon name='phone' />
            {property?.owner?.user?.phone}
          </button>
        </a>
      </div>

      <div className='photo-3d'>
        <div>
          {property?.conditions?.map((e, i) => (
            <li className='item' key={i}>
              {e}
            </li>
          ))}
        </div>
        <img
          src='https://s3-alpha-sig.figma.com/img/ea61/d118/237c2cf9ed17cd3ffa1deb7ecce8b5fb?Expires=1718582400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=pFaI8l3JrtIAEfon6XU20cAw7gUA4Tr4N920tdQRbl~sYMvna4s-VnaApk0mEMd9dzoyNuwUtSi-dS2vQ82vIBRB0Fsu84De2Tg6HYoP2slk3oLSeC7LZJjd~xOm--821KN0clK8Hiid9o2v1Ij-kA2IcMgw5wJQg0o3JSH~NfjRPjnDEKbtsME5VjvTgIgcNrlgO1ZtBhy~9OnLoWa~XSrg~~sLbd3054TTkYR1dO9Ro-Hvit5e66T6UpWHJByUYx6swVX2bBaGKWLLfzSHjc3aqXGDhhndaPI2006JOl3ci7U8yb9sLgaw643Fux5iuEjyvh34HuMzHGYiwe5Dvg__'
          width={'100%'}
          alt=''
        />
      </div>
      <div className='flex flex-icons'>
        {property?.équipement.map((e, i) => (
          <span className='item' key={i}>
            <Icon name='surface' />
            {e}
          </span>
        ))}
      </div>
    </div>
  );
}

export default Details;
