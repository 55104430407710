import React from 'react';
import './FormPropriete.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Icon from '../../../components/Icon/Icon';
import Upload from '../../../components/Upload/Upload';
import { useQuery } from 'react-query';
import { getOwners, getUsers } from '../../../utils/api/users';
import ListItems from '../../../components/ListItems/ListItems';
import Maps from '../../../components/Maps/Maps';
import {
  addProperty,
  deleteProperty,
  getProperty,
  updateProperty,
} from '../../../utils/api/properties';
import { zodResolver } from '@hookform/resolvers/zod';
import { PropertySchema } from '../../../utils/zod_check';
import FormPropertDetails from '../../../components/FormPropertDetails';
import Input from '../../../components/Inputs/Input';
import TextArea from '../../../components/Inputs/TextArea';
import Select from '../../../components/Inputs/Select';

function FormPropriete({
  DeleteProprety = () => {},
  SaveProprety = () => {},
  owners = [],
}) {
  const { id } = useParams();

  const { data: user } = useQuery('profile');

  const property_default = {
    vrUrl:
      'https://my.matterport.com/show/?m=jm5WwEA3HUN&log=0&help=0&nt=0&play=0&qs=0&brand=1&dh=1&tour=1&gt=1&hr=1&mls=0&mt=1&tagNav=1&pin=1&portal=1&f=1&fp=1&nozoom=0&search=1&wh=0&kb=1&lp=0&title=0&tourcta=1&vr=1',
    images: ['/images/slides/slide1.jpg'],
    owner: user?.role === 'Proprietaire' ? user?._id : '',
  };
  const { data: property, isLoading } = useQuery(
    `property/${id}`,
    id
      ? () => getProperty(id)
      : () => ({
          data: property_default,
          isLoading: true,
        })
  );

  const handleDeleteImage = (index) => {
    setValue(
      'images',
      watch('images').filter((_, i) => i !== index)
    );
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(PropertySchema),
    values: { latitude: 34, longitude: -6, ...property },
  });

  if (id && isLoading && user) return <>...loading</>;
  return (
    <div className='FormPropriete'>
      <div>
        <h2>à Propos de l’immeble</h2>
        <form
          action=''
          id='form-Property'
          onSubmit={handleSubmit(SaveProprety)}
        >
          <Input
            {...{
              register,
              errors,
              lable: 'Unité',
              name: 'unité',
            }}
          />
          <Select
            {...{
              register,
              errors,
              lable: 'Propriétaire',
              name: 'owner',
            }}
          >
            {owners?.map((o) => (
              <option value={o?._id} key={o?._id}>
                {o?.user?.first_name} {o?.user?.last_name}
              </option>
            ))}
          </Select>
          <TextArea
            {...{
              register,
              errors,
              lable: 'Description',
              name: 'description',
            }}
          />
          <div className='input-container full'>
            <label htmlFor='' className='bold'>
              Images
            </label>
            <div className='flex flex-upload'>
              {(watch('images') || [])?.map((image, index) => (
                <div className='upload' key={index}>
                  <img src={image} alt={`Image ${index + 1}`} />
                  <button
                    type='button'
                    onClick={() => handleDeleteImage(index)}
                  >
                    <Icon name='close' />
                  </button>
                </div>
              ))}

              <Upload
                onUpload={(url) =>
                  setValue('images', [...(watch('images') || []), url])
                }
              />
            </div>
            <span className='error'>
              {`${
                errors && errors['images']?.message
                  ? errors['images']?.message
                  : ''
              }`}
            </span>
          </div>
          <div className='line'></div>
          <TextArea
            {...{
              register,
              errors,
              lable: 'Adresse',
              name: 'address',
            }}
          />
          <div className='flex full'>
            <Input
              {...{
                register,
                errors,
                lable: 'Région',
                name: 'region',
              }}
            />
            <Input
              {...{
                register,
                errors,
                lable: 'Code postal',
                name: 'code_postal',
                type: 'number',
              }}
            />
            <Input
              {...{
                register,
                errors,
                lable: 'Pays',
                name: 'country',
              }}
            />
          </div>
          <div className='input-container full'>
            <label htmlFor='' className='bold'>
              Prix et durée
            </label>
          </div>
          <div className='flex full'>
            <div className='flex' style={{ gap: 0, flexWrap: 'nowrap' }}>
              <Input
                {...{
                  register,
                  errors,
                  lable: 'Prix de locations (MAD)',
                  name: 'price',
                  type: 'number',
                }}
              />
              <Select
                {...{
                  register,
                  errors,
                  lable: '',
                  name: 'par',
                  className: 'par',
                }}
              >
                <option value='mois'>Par mois</option>
                <option value='anne'>Par anné</option>
              </Select>
            </div>
          </div>
          <Input
            {...{
              register,
              errors,
              lable: 'Acompte (MAD)',
              name: 'Acompte',
              type: 'number',
            }}
          />{' '}
          <Select
            {...{
              register,
              errors,
              lable: 'Durée de location minimale (Mois)',
              name: 'durée',
              type: 'number',
            }}
          >
            <option value='6'>6 Mois</option>
            <option value='12'>12 Mois</option>
            <option value='24'>24 Mois</option>
          </Select>
          <div className='line'></div>
          <div
            className='flex mt-2 '
            style={{
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <div className='input-container'>
              <ListItems
                setValue={setValue}
                watch={watch}
                item_name='Conditions & restrictions'
                item='conditions'
              />
              <span className='error'>
                {`${
                  errors && errors['conditions']?.message
                    ? errors['conditions']?.message
                    : ''
                }`}
              </span>
            </div>
            <div className='input-container'>
              <ListItems setValue={setValue} watch={watch} />
              <span className='error'>
                {`${
                  errors && errors['équipement']?.message
                    ? errors['équipement']?.message
                    : ''
                }`}
              </span>
            </div>
          </div>
        </form>
      </div>

      <FormPropertDetails {...{ register, setValue, errors, watch, id }} />
    </div>
  );
}

export default FormPropriete;
