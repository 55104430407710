import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import Counts from '../../components/Dashboard/Counts/Counts';
import properties from '../Proprietes/properties.json';
import { useQuery, useQueryClient } from 'react-query';
import FicheIntervention from '../../components/Dashboard/FicheIntervention/FicheIntervention';
import { afficherTousFicheIntervention } from '../../utils/api/documents';
import CountsTechnicien from '../../components/Dashboard/Counts/CountsTechnicien';
function DashboardTechnicien() {
  const [propertyWithId1, setPropertyWithId1] = useState(null);
  const { data: user } = useQuery('profile');
  const { data: ListFicheIntervention } = useQuery(
    'ListFicheIntervention',
    afficherTousFicheIntervention,
    {
      refetchInterval: 5000,
    }
  );

  useEffect(() => {
    // Filter properties to get the property with ID 1
    const property = properties.find((property) => property.id === 1);
    setPropertyWithId1(property);
  }, []);
  return (
    <div className='Dashboard'>
      <h2>Bonjour {user?.first_name}, bon retour !</h2>
      <h6>Voici quelques informations importantes pour vous :</h6>
      <CountsTechnicien />
      <div className='grid-dash'>
        {ListFicheIntervention?.map((f, i) => (
          <FicheIntervention key={i} value={f} />
        ))}
      </div>
    </div>
  );
}

export default DashboardTechnicien;
