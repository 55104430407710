import React, { useEffect, useState } from 'react';
import './Users.css';

import Table from '../../components/Table/Table';
import { Link } from 'react-router-dom';
import { popup } from '../../components/Popup/Popup';
import Icon from '../../components/Icon/Icon';
import { useQuery } from 'react-query';
import { deleteUser, getUsers } from '../../utils/api/users';
import { queryClient } from '../../layouts/ReactQueryLayout';

function Users() {
  const keys = ['Utilisateur', 'ID', 'Role', 'Unité  alloué', 'Statut'];
  const { data: users, error, isLoading } = useQuery('users', getUsers);
  const { data: user } = useQuery('profile');

  const DeleteUser = (id) => {
    deleteUser({ id }).then((d) => queryClient.invalidateQueries('users'));
  };

  return (
    <div className='Users'>
      <h3>
        Gestion des utilisateurs
        <Link to={'/utilisateurs/ajouter'}>
          <button className='add'>
            <Icon name='add-cercle' />
            Ajouter
          </button>
        </Link>
      </h3>
      <Table
        keys={keys}
        isLoading={isLoading}
        list={users?.filter((u) => u?._id !== user?._id)}
        show_action={user?.role === 'Admin'}
      >
        {({ List }) =>
          List?.map((utilisateur, index) => (
            <tr key={index}>
              <td>
                <Link to={`/utilisateurs/modifier/${utilisateur?._id}`}>
                  <div className='avatar'>
                    <img
                      loading='lazy'
                      src={utilisateur?.profilePicture || '/logo-64.png'}
                      alt=''
                    />
                    <div>
                      <h5>{`${utilisateur?.first_name} ${utilisateur?.last_name}`}</h5>
                      <h6>{utilisateur?.email}</h6>
                    </div>
                  </div>
                </Link>
              </td>
              <td>{utilisateur?._id}</td>
              <td>{utilisateur?.role}</td>
              <td>{'Unité  alloué'}</td>
              <td>
                {
                  <span className={utilisateur?.status ? 'success' : 'dark'}>
                    {utilisateur?.status ? 'Actif' : 'Inactive'}
                  </span>
                }
              </td>
              {user?.role === 'Admin' && (
                <td>
                  <div className='flex '>
                    <Link
                      className='color-primary'
                      to={`/utilisateurs/modifier/${utilisateur?._id}`}
                    >
                      {' '}
                      <Icon name='edit' />{' '}
                    </Link>

                    <button
                      className='color-danger'
                      onClick={() => {
                        popup.show({
                          action: () => DeleteUser(utilisateur?._id),
                          title: `Supprimer l’utilisateur`,
                          type: 'confirmation',
                        });
                      }}
                    >
                      <Icon name='trash' />
                    </button>
                  </div>
                </td>
              )}
            </tr>
          ))
        }
      </Table>
    </div>
  );
}

export default Users;
