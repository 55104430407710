import React from 'react';
import './PaiementDetails.css';
import Icon from '../../../components/Icon/Icon';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { afficherPaiement } from '../../../utils/api/paiement';
import moment from 'moment';

function PaiementDetails() {
  const { id } = useParams();
  const { data: payment } = useQuery(`payments/${id}`, () =>
    afficherPaiement(id)
  );
  const { data: profile } = useQuery(`profile`);

  const objects = {
    admin: 'Payez votre abonnement',
    proprietaire: 'Paiement du loyer',
    technicien: 'Paiement au technicien',
  };

  return (
    <div className='Paiement PaiementDetails'>
      <h3 className='bold'>Transactions N 96</h3>
      <div className='SinglePaiemen'>
        <div className='container'>
          <div className='avatar'>
            <img src={profile?.profilePicture || '/images/avatar.png'} alt='' />
            <div>
              <h5>
                {profile?.first_name} {profile?.last_name}
              </h5>
              <h6>{profile?.email}</h6>
            </div>

            <p>-{payment?.montant} MAD</p>
          </div>
        </div>
        <div className='container'>
          <div className='flex'>
            <div>
              <p>Payé avec {payment?.payment_method}</p>
              <span className='line'></span>
              <p>ID de la transaction</p>
              <h6>SJ789SJD6BP</h6>
            </div>
            <div className='info'>
              <h5>Contact</h5>
              <span>{payment?.beneficiare?.email}</span>
              <span>{payment?.beneficiare?.phone}</span>
              <h5>Détails</h5>
              <span>
                Envoyé à {payment?.beneficiare?.first_name}{' '}
                {payment?.beneficiare?.last_name}{' '}
              </span>
              <span>
                {payment?.createdAt &&
                  moment(payment?.createdAt).format('DD/MM/yyyy')}
              </span>
              <h5>Objet</h5>
              <span>{objects[payment?.object || '']}</span>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='flex'>
            <div>
              <p className='flex' onClick={print} style={{ cursor: 'pointer' }}>
                <Icon name='print' />
                Imprimer le détail
              </p>
            </div>
            <div>
              <p>Besoin d'aide ?</p>
              <span className='info'>
                Si vous rencontrez un problème, assurez-vous de{' '}
                <Link to={'/contact'}>Nous contacter</Link>. Vous pourriez
                bénéficier de la <Link to={'/'}>protection des achats</Link>.
              </span>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='flex'>
            <span></span>
            <div>
              <p>
                NB:
                <span className='info'>
                  En automatisant cette opération, le même montant sera déduit
                  de votre compte chaque mois.
                </span>
              </p>
            </div>
          </div>
        </div>
        <img className='app-logo' src='/images/Logo.svg' alt='' />
      </div>
    </div>
  );
}

export default PaiementDetails;
