import React, { useEffect, useRef, useState } from 'react';
import './FicheInterventionTracking.css';
import Icon from '../../components/Icon/Icon';
import TrackingRight from '../../components/TrackingRight/TrackingRight';
import TrakingSteps from '../../components/TrackingSteps/TrakingSteps';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { afficherDocument } from '../../utils/api/documents';

function FicheInterventionTracking() {
  const ref = useRef();
  const { id } = useParams();
  const { data: document } = useQuery(
    `document/${id}`,
    () => afficherDocument(id),
    { refetchInterval: 1000 }
  );

  const Step = document?.step;

  return (
    <div className='Tracking'>
      <div className='Tracking-container'>
        {/* <div className='flex'>
          <button className='add' onClick={() => Next(Step - 1)}>
            <Icon name='back' />
            Retour
          </button>
          <button className='add' onClick={() => Next(Step + 1)}>
            Next
            <Icon name='next' />
          </button>
        </div> */}
        {Step <= 5 ? (
          <div className='loading'>
            <Icon name='loading' />
            <h2>En cours de traitement </h2>
          </div>
        ) : (
          <div className='success'>
            <div className='relative'>
              <img height={260} width={260} src='/images/success.png' />
              <Icon name='success' />
            </div>
            <h1>Succès </h1>
          </div>
        )}
        <div>
          <h3>Merci</h3>
          {Step <= 5 ? (
            <p>Votre intervention 1872 Est envoyé</p>
          ) : (
            <p>Votre demande d’ intervention 1872 est bien traitée</p>
          )}
          <TrakingSteps Step={Step} SwiperRef={ref} />
        </div>
      </div>
      <TrackingRight
        button={Step > 5 ? 'Télécharger la fiche d’intervention' : ''}
        value={document}
      />
    </div>
  );
}

export default FicheInterventionTracking;
