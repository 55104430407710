import React, { useMemo, useState } from 'react';
import './HistoriquePaiements.css';
import Icon from '../../Icon/Icon';
import moment from 'moment';

function HistoriquePaiements({ payments }) {
  const months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  const [Count, setCount] = useState({});
  useMemo(() => {
    try {
      payments.map((item) =>
        setCount((c) => ({
          ...c,
          [moment(item?.createdAt).format('MM')]: {
            confirmed: item?.confirmed,
          },
        }))
      );
    } catch (error) {}
  }, [payments]);
  console.log(Count);
  return (
    <div className='HistoriquePaiements'>
      <h2>
        Historique de paiements <span>{moment().format('YYYY')}</span>
      </h2>
      <div className='months'>
        {months?.map((m, i) => (
          <div
            className={
              Count[i < 9 ? `0${i + 1}` : i + 1]
                ? Count[i < 9 ? `0${i + 1}` : i + 1].confirmed
                  ? 'active'
                  : 'warning'
                : ''
            }
            key={i}
          >
            {m}
            <Icon name='success' />
          </div>
        ))}
      </div>
      <div className='nbmois'>
        <div className='month-count'>
          <svg
            viewBox='0 0 68 68'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle
              r={30}
              stroke='#D0E2FF'
              strokeWidth={6}
              cx={34}
              cy={34}
            ></circle>
            <circle
              r={30}
              stroke='#377DFF'
              strokeWidth={6}
              strokeDasharray={190}
              strokeDashoffset={190 - (Object.keys(Count)?.length * 190) / 12}
              strokeLinecap='round'
              cx={34}
              cy={34}
            ></circle>
          </svg>
          <div className='c'>
            <p>Statut</p>
            <span>{Object.keys(Count)?.length} / 12</span>
          </div>
        </div>
        <div style={{ minWidth: 280, flex: 1 }}>
          <h5>Nombre de mois consommées</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HistoriquePaiements;
