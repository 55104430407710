import axios from 'axios';
import { Axios } from './axios';
import { queryClient } from '../../layouts/ReactQueryLayout';

const afficherTousPaiements = () => {
  return Axios.get('/payments/').then((response) => {
    console.log(response?.data);
    return response.data?.payments;
  });
};

const ajouterPaiement = (paiementData) => {
  return Axios.post('/payments/', paiementData).then((response) => {
    console.log(response?.data);
    queryClient.invalidateQueries('payments');
    return response.data;
  });
};

const afficherPaiement = (id) => {
  return Axios.get(`/payments/${id}`).then((response) => {
    console.log({ payments: response?.data?.payment });
    return response.data?.payment;
  });
};

export { afficherTousPaiements, ajouterPaiement, afficherPaiement };
