import React, { useState } from 'react';
import './StagingList.css';
import Table from '../../../components/Table/Table';
import { useQuery } from 'react-query';
import { getAllStagings } from '../../../utils/api/staging';
import { Link } from 'react-router-dom';
import Icon from '../../../components/Icon/Icon';
import moment from 'moment';
import toast from 'react-hot-toast';

function StagingList() {
  const keys = [
    'ID Visite',
    'Détails de la visite',
    'Statut',
    'Date',
    'Mise a Jour',
  ];

  const { data: stagings, isLoading } = useQuery('tours', getAllStagings);
  return (
    <div className='StagingList Users'>
      <h3>
        Gestion des visites
        <Link to={'/staging/ajouter'}>
          <button className='add'>
            <Icon name='add-cercle' />
            Ajouter
          </button>
        </Link>
      </h3>
      <Table
        keys={keys}
        isLoading={isLoading}
        list={stagings?.filter((o) => o.objects?.length > 0)}
      >
        {({ List }) =>
          List?.map((staging, index) => (
            <tr key={index}>
              <td>{staging?.tourId}</td>
              <td>
                <Link to={`/staging/${staging?._id}`}>
                  <div className='avatar'>
                    <img
                      loading='lazy'
                      src={`${staging?.screenShotUrl}`}
                      alt=''
                      style={{ height: 80, width: 170, borderRadius: 8 }}
                    />
                    <div>
                      <p>Visite Anfa Hassan {index}</p>
                    </div>
                  </div>
                </Link>
              </td>
              <td>
                {
                  <span className={true ? 'success' : 'dark'}>
                    {true ? 'Actif' : 'Inactive'}
                  </span>
                }
              </td>
              <td>{moment(staging?.createdAt).format('DD-MM-YYYY')}</td>
              <td>{moment(staging?.updatedAt).format('DD-MM-YYYY')}</td>

              <td>
                <div className='flex '>
                  <Link
                    className='color-primary'
                    to={`/staging/${staging?._id}`}
                    style={{
                      background: '#0000',
                      fontSize: 20,
                    }}
                  >
                    {' '}
                    <Icon name='play' />{' '}
                  </Link>

                  <button
                    style={{
                      background: '#0000',
                      fontSize: 20,
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.origin}/staging/${staging?._id}`
                      );
                      toast.success('Link Copied Successfully');
                    }}
                  >
                    <Icon name='join' />
                  </button>
                </div>
              </td>
            </tr>
          ))
        }
      </Table>
    </div>
  );
}

export default StagingList;
