import React from 'react';
import Icon from '../../../components/Icon/Icon';

function CaptureServiceRV() {
  return (
    <div className='Gestion'>
      <div className='top'>
        <h2>Capture service</h2>
      </div>
      <div className='body'>
        <div className='body-container'>
          <h4>Gestion de disponibiltés</h4>
          <div className='flex'>
            <select name='' id=''>
              {[
                'janvier',
                'février',
                'mars',
                'avril',
                'mai',
                'juin',
                'juillet',
                'aout',
                'septembre',
                'octobre',
                'novembre',
                'décembre',
              ].map((y) => (
                <option key={y}>{y}</option>
              ))}
            </select>

            <select name='' id=''>
              {['2020', '2021', '2022', '2023', '2024'].map((y) => (
                <option key={y}>{y}</option>
              ))}
            </select>
          </div>
          <div className='times'>
            {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(
              (_, i) => (
                <div className='input-container ' key={i}>
                  <div className='check-box check-input'>
                    <input type='checkbox' name='rôle' value={''} />
                    <div className='out'>
                      <Icon name='checkbox' />
                    </div>
                    Lundi 01-Mai-2024
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div className='img-container'>
          <img src='/images/matterport-3d.png' alt='' />
          <button className='add'>Marquez comme indisponible</button>
        </div>
      </div>
    </div>
  );
}

export default CaptureServiceRV;
