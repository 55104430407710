import React, { Suspense, lazy } from 'react';
const PdfCreator = lazy(() => import('../../PdfCreator/PdfCreator'));
import ContratDeBail from '../../../components/Docs/ContratDeBail';
import ContratDeBailForm from '../../../components/Docs/Forms/ContratDeBailForm';
import { ajouterContratBail } from '../../../utils/api/documents';
import toast from 'react-hot-toast';

function ContratDeBailPage() {
  return (
    <Suspense fallback={<>Loading...</>}>
      <PdfCreator
        type={'contrat_de_bail'}
        Element={ContratDeBail}
        Form={ContratDeBailForm}
        onsubmit={(data) => {
          const {
            user,
            adresse_du_locataire,
            locataire_first_name,
            locataire_last_name,
            unité,
          } = data;
          if (
            user &&
            adresse_du_locataire &&
            locataire_first_name &&
            locataire_last_name &&
            unité
          )
            return ajouterContratBail(data).catch((err) => console.log(err));
          else toast.error('Données incomplètes');
        }}
      />
    </Suspense>
  );
}

export default ContratDeBailPage;
