import React, { useState } from 'react';

import { environment } from '../../EnvDev';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

function Maps({
  position = { lat: 34, lng: -6 },
  setPosition = ({ lat, lng }) => {},
}) {
  console.log(position);
  return (
    <APIProvider apiKey={environment.maps}>
      <Map
        defaultCenter={position}
        style={{ height: 400 }}
        defaultZoom={14}
        styles={'custom'}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
        onClick={(v) => {
          if (v?.detail?.latLng) {
            console.log(v);
            setPosition(v?.detail?.latLng);
          }
        }}
      >
        <Marker position={position} />
      </Map>
    </APIProvider>
  );
}

export default Maps;
