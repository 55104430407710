import { Axios } from "./axios";

const upload = (file) => {
  const formData = new FormData();
  formData.append("image", file);
  return Axios.post(`/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Important for file uploads
    },
  }).then(({ data }) => {
    console.log(data);
    return {
      file: data?.file,
      filename: data?.filename,
    };
  });
};

const upload_64 = () => {
  return new Promise((resolve) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  });
};

const upload_file = (file) => {
  const formData = new FormData();

  formData.append("image", file);

  return Axios.post(`/upload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Important for file uploads
    },
  }).then(({ data }) => {
    const { file, size } = data;

    return { file, size };
  });
};

export { upload, upload_64, upload_file };
