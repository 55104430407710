import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import Icon from '../../components/Icon/Icon';
import Counts from '../../components/Dashboard/Counts/Counts';
import Chart from '../../components/Dashboard/Chart/Chart';
import HomeCard2 from '../../components/Dashboard/HomeCard/HomeCard2';
import { useQuery, useQueryClient } from 'react-query';
import FicheIntervention from '../../components/Dashboard/FicheIntervention/FicheIntervention';
import HistoriquePaiements from '../../components/Dashboard/HistoriquePaiements/HistoriquePaiements';
import {
  afficherTousFicheIntervention,
  affichertousDocuments,
} from '../../utils/api/documents';
import { getProperties } from '../../utils/api/properties';
import { getDashboard } from '../../utils/api/dashboard';
import { afficherTousPaiements } from '../../utils/api/paiement';
function DashboardLocataire() {
  const { data: user } = useQuery('profile');
  const { data: properties, isLoading } = useQuery('properties', getProperties);
  const { data: dashboard, isLoading: isLoadingDash } = useQuery(
    'dashboard',
    getDashboard
  );

  const { data: documents } = useQuery('documents', affichertousDocuments, {
    refetchInterval: 5000,
  });
  const fiches = documents?.filter(
    (d) => d?.documentType === 'fiche_intervention_maintenance'
  );

  return (
    <div className='Dashboard'>
      <h2>Bonjour {user?.first_name}, bon retour !</h2>
      <h6>Voici quelques informations importantes pour vous :</h6>

      <div className='grid-dash'>
        <HomeCard2
          isLoading={isLoading}
          propriete={properties ? properties[0] : {}}
        />

        <HistoriquePaiements payments={dashboard?.payments || []} />
        {fiches?.map((f, i) => (
          <FicheIntervention key={i} value={f} />
        ))}
      </div>
    </div>
  );
}

export default DashboardLocataire;
