import React from 'react';
import Icon from '../../components/Icon/Icon';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getAllCaptures } from '../../utils/api/capture';
import 'moment/locale/fr'; // without this line it didn't work
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
moment.locale('fr');

function Demandes({
  title = 'Demandes actuelles',
  type = 'Capture service',
  link = 'captures',
}) {
  const { data: captures } = useQuery('captures', getAllCaptures);

  return (
    <div className='Demandes'>
      <h2>{title}</h2>
      <Link to={'/rendez-vous/demandes'}>
        Voir toutes les résérvations ({captures?.length || 0})
      </Link>
      <div className='Demands-container'>
        {captures?.map((capture, i) => (
          <div
            className={`Demande ${
              capture?.annuler
                ? 'disabled'
                : capture?.accepted
                ? 'accepted'
                : ''
            }`}
            key={i}
          >
            <h3>
              <Icon name='info' />
              Le {moment(capture?.date).format('dddd DD MMMM ')} à{' '}
              {capture?.time} (GMT+)
            </h3>
            <p>
              Demande de {type}
              <span>- {capture?.name}</span>
            </p>

            <Link
              to={`/rendez-vous/${link}/${capture?._id}`}
              className={
                capture?.annuler
                  ? 'annule'
                  : capture?.accepted
                  ? 'accepted'
                  : ''
              }
            >
              {capture?.annuler
                ? 'Annulé'
                : capture?.accepted
                ? 'Accepté'
                : 'Gérer'}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Demandes;
