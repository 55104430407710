import React, { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Documents from '../../screens/Documents/Documents';
import AppLayout from '../AppLayout';
import FicheInterventionTracking from '../../screens/FicheInterventionTracking/FicheInterventionTracking';
import FicheInterventionTrackingTechnicien from '../../screens/FicheInterventionTracking/FicheInterventionTrackingTechnicien';
import FicheDInterventionPage from '../../screens/Documents/CreateDocumnet/FicheDInterventionPage.js';
import QuitanceDeLoyerPage from '../../screens/Documents/CreateDocumnet/QuitanceDeLoyerPage.js';
import ContratDeBailPage from '../../screens/Documents/CreateDocumnet/ContratDeBailPage.js';
import FacturePage from '../../screens/Documents/CreateDocumnet/FacturePage.js';

const documentRouter = {
  path: '/documents',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    {
      path: '',
      element: <Documents />,
    },

    {
      path: 'create',
      element: <Outlet />,
      children: [
        {
          path: 'fiche_intervention_maintenance',
          element: <FicheDInterventionPage />,
        },
        {
          path: 'contrat_de_bail',
          element: <ContratDeBailPage />,
        },
        {
          path: '',
          element: (
            <Navigate
              to='/documents/create/fiche_intervention_maintenance'
              replace
            />
          ),
        },
      ],
    },
    {
      path: ':id',
      element: <Documents />,
    },
  ],
};

const documentRouterLocataire = {
  path: '/documents',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    {
      path: '',
      element: <Documents />,
    },
    {
      path: 'quittance/:id',
      element: <QuitanceDeLoyerPage />,
    },

    {
      path: 'create',
      element: <Outlet />,
      children: [
        {
          path: 'fiche_intervention_maintenance',
          element: <FicheDInterventionPage />,
        },
        {
          path: '',
          element: (
            <Navigate
              to='/documents/create/fiche_intervention_maintenance'
              replace
            />
          ),
        },
      ],
    },
    {
      path: ':id',
      element: <Documents />,
    },
  ],
};

const factureRouter = {
  path: '/facture',
  element: (
    <AppLayout>
      <FacturePage />
    </AppLayout>
  ),
};

const trackingRouter = {
  path: '/tracking/:id',
  element: <FicheInterventionTracking />,
};

const trackingRouterTechnicien = {
  path: '/tracking/:id',
  element: <FicheInterventionTrackingTechnicien />,
};

export {
  documentRouter,
  documentRouterLocataire,
  factureRouter,
  trackingRouter,
  trackingRouterTechnicien,
};
