import React from 'react';
import './HomeCard.css';
import { Link } from 'react-router-dom';
import Icon from '../../Icon/Icon';

function HomeCard2({ propriete, isLoading }) {
  if (isLoading)
    return (
      <div className='LoadingAnime'>
        <div className='card-2'>
          <div className='HomeCard'>
            <div className='img anime'></div>
            <div className='card-body'>
              <div className='flex'>
                <p className='price anime'></p>
                <button
                  className='anime'
                  style={{ width: 100, height: 40 }}
                ></button>
              </div>
              <div>
                <h5 className='anime'> </h5>
              </div>
              <div className='line' />
              <div className='flex flex-last'>
                <div className='item anime'></div>
                <div className='item anime'></div>
                <div className='item anime'></div>
                <div className='item anime'></div>
              </div>
            </div>
          </div>
        </div>{' '}
      </div>
    );
  if (propriete?._id)
    return (
      <Link to={`/proprietes/${propriete._id}`}>
        <div className='card-2'>
          <div className='HomeCard'>
            <img className='' src={propriete.images[0]} alt='' />

            <div className='card-body'>
              <div className='flex'>
                <div className='price'>
                  {propriete.price}
                  <span>MAD</span>
                  <span>/Mo</span>
                </div>
                <button>Disponible</button>
              </div>
              <div>
                <h4> {propriete?.unité}</h4>
                <p className='diponible '>
                  <Icon name='position' /> {propriete?.address}
                </p>
              </div>
              <div className='line'></div>
              <div className='flex flex-last'>
                {propriete?.équipement?.map((équipement, i) => (
                  <div className='item' key={i}>
                    {/* <Icon name='bed' /> */}
                    <Icon name='surface' />
                    <span /> {équipement}
                  </div>
                ))}

                {/* <div className='item'>
                  <Icon name='bathroom' />
                  <span /> 2 Bathrooms
                </div>
                <div className='item'>
                  <Icon name='car' />
                  <span /> 1 Parking
                </div>
                <div className='item'>
                  <Icon name='surface' />
                  <span />
                  250 m²
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
}

export default HomeCard2;
