import { useQuery } from 'react-query';
import './CallData.css';
import React from 'react';
import { getClients, getVisit, getVisits } from '../../../utils/api/visits';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { timeCalc } from '../../../utils/timeCalc';

function CallData() {
  const { id } = useParams();
  const { data: visits } = useQuery(`visits`, getVisits);

  let new_array=[];

  visits?.map(v=>{
    if(v?.client?.visits?.length>0)
      new_array=[...new_array,...v?.client?.visits?.map(vs=>({...vs,nom:v?.client?.nom,email:v?.client?.email}))]
  })
  

  console.log({new_array})
  return (
    <div className='CallData'>
      {/* <div className='inputs-label'>
        <div className='input-container'>
          <label htmlFor=''>Client</label>
          <select
            type='text'
            // {...register('client.nom')}
            placeholder='tapez votre nom et prénom'
          >
            {visit?.map(({ client }, i) => (
              <option value={client?._id}>{client?.nom}</option>
            ))}
          </select>
        </div>
        <div className='input-container'>
          <label htmlFor=''>Tours</label>
          <select
            type='text'
            // {...register('client.nom')}
            placeholder='tapez votre nom et prénom'
          >
            {visit?.map(({ client }, i) => (
              <option value={client?._id}>{client?.nom}</option>
            ))}
          </select>
        </div>
        <div className='input-container'>
          <label htmlFor=''>Date</label>
          <input type='date' name='' id='' />
        </div>
      </div> */}
      <div className='table'>
        <table>
          <thead>
            <tr>
              <th>Tour</th>
              <th>Client</th>

              <th>Heure de début</th>
              <th>Heure de fin</th>
              <th>Durée</th>
            </tr>
          </thead>
          <tbody>
            {new_array?.length>0&&new_array?.map((v, i) => (
                <tr key={i}>
                  <td>
                    {v?.property?.unité}
                  </td>
                  <td>
                    <div>
                      <div className='avatar'>
                        <img
                          src='/images/users/profile.jpeg'
                          alt=''
                          srcset=''
                        />
                        <div>
                          <span>
                            {v?.nom}
                          </span>
                          <p>
                            {
                              v?.email
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>
                    {moment(v?.startTime).format('hh:mm')}
                  </td>
                  <td>  { moment(v?.endTime).format('hh:mm')}</td>
                  <td>
                    <span className="time">
                    {timeCalc(v?.startTime,v?.endTime)}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CallData;
