import React, { Suspense, lazy, useEffect, useRef, useState } from 'react';
import './FicheInterventionTracking.css';
import Icon from '../../components/Icon/Icon';
import TrackingRight from '../../components/TrackingRight/TrackingRight';
import TrakingSteps from '../../components/TrackingSteps/TrakingSteps';
const PdfReader = lazy(() => import('../../components/PdfReader/PdfReader'));
import { useForm } from 'react-hook-form';
import ListItems from '../../components/ListItems/ListItems';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  afficherDocument,
  modifierFicheIntervention,
} from '../../utils/api/documents';
import moment from 'moment';

function FicheInterventionTrackingTechnicien() {
  const ref = useRef();

  const { id } = useParams();
  const { data: document } = useQuery(`document/${id}`, () =>
    afficherDocument(id)
  );

  const router = useNavigate();

  const { register, watch, setValue } = useForm({
    values: {
      pièces_rempalcés: document?.pièces_rempalcés,
      equipement: document?.equipement,
      technicienType: document?.technicienType,
    },
  });

  const Step = document?.step;

  useEffect(() => {
    ref?.current?.swiper?.slideTo(Step - 1);
  }, [document?.step]);

  const chech_box = [
    {
      id: 3,
      name: 'Effectuer la visite initiale',
      inf: 'Intervention accordée au technicien Hamid 3938',
    },
    {
      id: 4,
      name: 'Débuter l’intervention',
      inf: 'Le client a soumis le problème aux techniciens',
    },
    {
      id: 5,
      name: 'Signer et confirmer la fin d’intevention',
      inf: 'Le client a soumis le problème aux techniciens',
    },
  ];

  const update = (data) => {
    modifierFicheIntervention(document?._id, data)
      .then((data) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const techniciens = [
    'Plomberie',
    'Électricien Bâtiment',
    'Technicien CVC',
    'Femme de Ménage',
  ];

  return (
    <div className='Tracking Tracking-Technicien'>
      <div className='Tracking-container'>
        {Step === 1 || !document?.accepted ? (
          <Suspense fallback={<>Loading...</>}>
            <PdfReader
              url={document?.url}
              Bottom={
                <div className='bottom'>
                  <Link to='/'>
                    <button className='add white'>Retour</button>
                  </Link>
                  <button
                    className='add'
                    onClick={() =>
                      update({
                        ...document,
                        accepted: true,
                        step: 2,
                      })
                    }
                  >
                    Accepter
                  </button>
                </div>
              }
            />
          </Suspense>
        ) : (
          <>
            <div className='Technicien'>
              <h2>Mon progrès</h2>
              <div className='flex'>
                <div className='table'>
                  <table>
                    <tr>
                      <th>Propriété</th>
                      <th>Date De demande</th>
                      <th>Demandé par</th>
                    </tr>
                    <tr>
                      <td>{document?.unité?.unité}</td>
                      <td>
                        {moment(document?.date || '')?.format('DD/MM/YYYY')}
                      </td>
                      <td>
                        {document?.user?.first_name} {document?.user?.last_name}
                      </td>
                    </tr>
                  </table>
                </div>
                <div className='table'>
                  <table>
                    <tr>
                      <th>Date prévue de fin</th>
                    </tr>
                    <tr>
                      <td>
                        j’ai acheté La pièce NH98 en espèce, le bon serait
                        envoyé avec la fiche d’intervention
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div className='Description'>
                <h2>Description du problème</h2>
                <p>{document?.description}</p>
              </div>
              <h2>Mon progrès</h2>
              <form action='' style={{ marginTop: 20, gap: 40 }}>
                <div>
                  {chech_box?.map((item, i) => (
                    <div key={i} className='input-container '>
                      <div className='check-box check-input'>
                        <input
                          type='checkbox'
                          name='step'
                          // {...register(``)}
                          onClick={() => {
                            update({
                              ...document,
                              accepted: true,
                              step: item?.id,
                            });
                          }}
                          checked={Step >= item?.id}
                          // checked={Step >= item?.id}
                        />
                        <div className='out'>
                          <Icon name='checkbox' />
                        </div>
                        <p>
                          {item?.name}
                          <span>{item?.inf}</span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                {Step === 2 && <img src='/images/image 20.png' />}
                {Step === 3 && (
                  <div style={{ flex: 1 }}>
                    <div className='input-container '>
                      <select
                        {...register('technicienType')}
                        onChange={(e) =>
                          update({
                            ...document,
                            technicienType: e?.target?.value,
                          })
                        }
                      >
                        {techniciens?.map((t) => (
                          <option value={t} key={t}>
                            {t}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div
                      className='flex mt-2 '
                      style={{ justifyContent: 'space-between' }}
                    >
                      <ListItems
                        setValue={(key, value) => {
                          update({
                            ...document,
                            [key]: value,
                          });
                        }}
                        watch={watch}
                        item='equipement'
                        item_name='équipement'
                      />
                      <ListItems
                        setValue={(key, value) => {
                          update({
                            ...document,
                            [key]: value,
                          });
                        }}
                        watch={watch}
                        item_name='Interventions effectuées'
                        item='pièces_rempalcés'
                      />
                    </div>
                  </div>
                )}

                {Step == 4 && (
                  <>
                    <div className='line'></div>
                    <div className='success'>
                      <div className='relative'>
                        <img
                          height={260}
                          width={260}
                          src='/images/success.png'
                        />
                        <Icon name='success' />
                      </div>
                      <p>Votre intervention a été effectuée avec succès</p>
                      <span>
                        Veuillez signez et confirmer la finalisation de
                        l’intervention
                      </span>
                      <div className='input-container'>
                        <p>Siganture Technicien</p>
                        <div className='flex '>
                          <input
                            type='text'
                            value={`${document?.technicien?.first_name} ${document?.technicien?.last_name}`}
                          />
                          <button
                            className='add'
                            type='button'
                            onClick={() =>
                              update({
                                ...document,
                                step: Step + 2,
                              })
                            }
                          >
                            Confirmer
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </form>
            </div>
            <div>
              <TrakingSteps Step={Step} SwiperRef={ref} />
            </div>
          </>
        )}
      </div>
      <TrackingRight
        click={() => router(`/documents/${id}`)}
        // button={Step > 1 ? 'Next' : ''}
        button='Télécharger la fiche d’intervention'
        value={document}
      />
    </div>
  );
}

export default FicheInterventionTrackingTechnicien;
