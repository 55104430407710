import React from 'react';

const VisiteVirtuelle = ({ property }) => {
  return (
    <div className='VisiteVirtuelle'>
      <iframe
        src={
          property?.vrUrl ||
          'https://my.matterport.com/show/?m=jm5WwEA3HUN&log=0&help=0&nt=0&play=0&qs=0&brand=1&dh=1&tour=1&gt=1&hr=1&mls=0&mt=1&tagNav=1&pin=1&portal=1&f=1&fp=1&nozoom=0&search=1&wh=0&kb=1&lp=0&title=0&tourcta=1&vr=1'
        }
        frameborder='0'
        height={400}
        width={'100%'}
      ></iframe>
    </div>
  );
};

export default VisiteVirtuelle;
