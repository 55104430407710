import React, { Suspense, lazy } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Login from '../screens/Authentification/Login';
import Register from '../screens/Authentification/Register';
import OTP from '../screens/Authentification/OTP/OTP';
const RoleRouter = lazy(() => import('./RoleRouter.js'));

import { useQuery } from 'react-query';
import { getProfile, getUser } from '../utils/api/users';

import LoadingAnime from '../screens/LoadingAnime/LoadingAnime';
import ShowStaging from '../screens/Staging/ShowStaging.js';

function RouterLayout() {
  const { data: token, isLoadingToken } = useQuery('token', () => {
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      return localStorage.getItem('token');
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
    }
    return;
  });

  const { data: user, error, isLoading } = useQuery('profile', getProfile);

  const authRouter = createBrowserRouter([
    {
      path: '/register',
      element: <Register />,
    },
    {
      path: '/staging/:id',
      element: <ShowStaging />,
    },
    {
      path: '/*',
      element: <Login />,
    },
  ]);

  const otpRouter = createBrowserRouter([
    {
      path: '/*',
      element: <OTP />,
    },
  ]);

  if (isLoading || isLoadingToken || error) return <LoadingAnime />;
  if (!token) return <RouterProvider router={authRouter} />;
  if (user && user?.email && !user?.otpVerified)
    return <RouterProvider router={otpRouter} />;

  return (
    <Suspense fallback={<LoadingAnime />}>
      <RoleRouter user={user} />
    </Suspense>
  );
}

export default RouterLayout;
