import { RendezVousRouter } from '../layouts/Routes/RendezVousRouter';
import { avatarRouter } from '../layouts/Routes/avatarRouter';
import { callRouter } from '../layouts/Routes/callRouter';
import { capture_serviceRouter } from '../layouts/Routes/capture_serviceRouter';
import {
  dashboardLocatairRouter,
  dashboardRouter,
  dashboardTechnicienRouter,
} from '../layouts/Routes/dashboardRouter';
import {
  documentRouter,
  documentRouterLocataire,
  factureRouter,
  trackingRouter,
  trackingRouterTechnicien,
} from '../layouts/Routes/documentRouter';
import { etatLieuxRouter } from '../layouts/Routes/etatLieuxRouter';
import { paiementsRouter } from '../layouts/Routes/paiementsRouter';
import {
  locataireProprietesRouter,
  proprietesOwenerRouter,
  proprietesRouter,
} from '../layouts/Routes/proprietesRouter';
import { stagingRouter } from '../layouts/Routes/stagingRouter';
import { ownerRouter, usersRouter } from '../layouts/Routes/usersRouter';

const adminRoutes = [
  {
    link: '/',
    icon: 'grid',
    name: 'Vue d’ensemble',
    test: '',
    route: dashboardRouter,
  },
  {
    link: '/utilisateurs',
    icon: 'users',
    name: 'Gestion des utilisateurs',
    route: usersRouter,
  },
  {
    link: '/proprietes',
    icon: 'key',
    name: 'Gestion de Propriétés',
    route: proprietesRouter,
  },
  {
    link: '/paiements',
    icon: 'eur',
    name: 'Gestion financière',
    route: paiementsRouter,
  },
  {
    link: '/documents',
    icon: 'file',
    name: 'Mes Documents',
    route: documentRouter,
  },
  {
    link: '/capture_service',
    icon: 'box',
    name: 'Capture service',
    route: capture_serviceRouter,
  },
  {
    link: '/facture',
    icon: 'add-file',
    name: 'Ajouter une facture',
    route: factureRouter,
  },
  {
    link: '/rendez-vous',
    icon: 'calendar',
    name: 'Gestion des rendez-vous',
    route: RendezVousRouter,
  },
  {
    link: '/etatLieux',
    icon: 'pc',
    name: 'Etat des lieux',
    route: etatLieuxRouter,
  },
  {
    link: '/staging',
    icon: 'staging',
    name: 'Home staging',
    route: stagingRouter,
  },
  {
    link: '/avatar',
    icon: 'avatar',
    name: 'Hub & avatar',
    route: avatarRouter,
  },
  {
    link: '/call',
    route: callRouter,
  },

  { route: trackingRouter },
];

const proprietaireRoutes = [
  { link: '/', icon: 'grid', name: 'Vue d’ensemble', route: dashboardRouter },
  {
    link: '/utilisateurs',
    icon: 'users',
    name: 'Gestion des utilisateurs',
    route: ownerRouter,
  },
  {
    link: '/proprietes',
    icon: 'key',
    name: 'Gestion de Propriétés',
    route: proprietesOwenerRouter,
  },
  {
    link: '/paiements',
    icon: 'eur',
    name: 'Gestion financière',
    route: paiementsRouter,
  },
  {
    link: '/documents',
    icon: 'file',
    name: 'Mes Documents',
    route: documentRouter,
  },
  {
    link: '/capture_service',
    icon: 'box',
    name: 'Capture service',
    route: capture_serviceRouter,
  },
  {
    link: '/facture',
    icon: 'add-file',
    name: 'Ajouter une facture',
    route: factureRouter,
  },
  {
    link: '/rendez-vous',
    icon: 'calendar',
    name: 'Gestion des rendez-vous',
    route: RendezVousRouter,
  },
  { route: trackingRouter },
];

const locataireRoutes = [
  {
    link: '/',
    icon: 'grid',
    name: 'Vue d’ensemble',
    route: dashboardLocatairRouter,
  },
  {
    link: '/paiements',
    icon: 'eur',
    name: 'Gestion financière',
    route: paiementsRouter,
  },
  {
    link: '/documents',
    icon: 'file',
    name: 'Mes Documents',
    route: documentRouterLocataire,
  },
  { route: locataireProprietesRouter },
  { route: trackingRouter },
];

const technicienRoutes = [
  {
    link: '/',
    icon: 'grid',
    name: 'Vue d’ensemble',
    route: dashboardTechnicienRouter,
  },
  {
    link: '/documents',
    icon: 'file',
    name: 'Mes Documents',
    route: documentRouter,
  },
  { route: trackingRouterTechnicien },
];

const getRoleRoutes = (user) => {
  let router = [];

  switch (user?.role) {
    case 'Admin':
      router = adminRoutes;

      break;
    case 'Propriétaire':
      router = proprietaireRoutes;

      break;
    case 'Locataire':
      router = locataireRoutes;

      break;
    case 'Technicien':
      router = technicienRoutes;
      break;
  }

  return { router };
};

const Roles = {
  Admin: 'admin',
  Propriétaire: 'owner',
  Locataire: 'renter',
  Technicien: 'technicien',
};

export { getRoleRoutes, Roles, adminRoutes };
