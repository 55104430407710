import React, { useState } from 'react';
import './FicheIntervention.css';
import Icon from '../../Icon/Icon';
import { Link } from 'react-router-dom';
import moment from 'moment';

function FicheIntervention({ value }) {
  const Value = Math.floor((100 * value?.step) / 5);
  const steps = [
    'Fiche d’intervention envoyé',
    'Fiche d’intervention Accepté',
    'Visite initiale',
    'Début d’intervention',
    'Confirmation et signatures',
  ];

  return (
    <div className='FicheIntervention'>
      <div className='flex-left'>
        <div className='timer'>
          <Icon name='timer' />
        </div>
        <div>
          <h5>Commencé le</h5>
          <p>
            <Icon name='watch' />
            {moment(value?.date)?.format('DD/MM/YYYY')}
          </p>
        </div>
      </div>
      <div className='flex-center'>
        <h4>Fiche d’intervention {value?.documentId}</h4>
        {Value > 0 && (
          <>
            {' '}
            <span>
              Intervention de réparation de piscine programmée pour une
              propriété en location
            </span>
            <div className='flex' style={{ color: '#768298' }}>
              <Icon name='copy' /> étape {value?.step} :{' '}
              {steps[value?.step || '']}
              chimique
            </div>
          </>
        )}
      </div>
      {Value > 0 && (
        <div className='flex-center'>
          <h3>
            {Value < 100 ? (
              `${Value}% de Progrès`
            ) : (
              <div className='flex'>
                Terminer
                <Icon name='success' />
              </div>
            )}
          </h3>
          <div className='slider'>
            <div style={{ width: `${Value}%` }}></div>
          </div>
        </div>
      )}

      <div className='flex-center'>
        {Value === 100 ? (
          <Link to={`/documents/${value?._id}`}>
            <button className='add '>
              <Icon name='eye' />
              Visualiser
            </button>
          </Link>
        ) : Value === 0 ? (
          <Link to={`/tracking/${value?._id}`}>
            <button className='add '>
              <Icon name='eye' />
              Visualiser
            </button>
          </Link>
        ) : (
          <Link to={`/tracking/${value?._id}?step=${value?.step}`}>
            <button className='add '>
              <Icon name='eye' />
              Suivre
            </button>
          </Link>
        )}
      </div>
    </div>
  );
}

export default FicheIntervention;
