import React from 'react';
import Icon from '../../components/Icon/Icon';
import { getCapture, updateCapture } from '../../utils/api/capture';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

function DemandeCapture() {
  const { id } = useParams();
  const router = useNavigate();
  const { data: capture } = useQuery('capture', () => getCapture(id));
  const UpdateCapture = (data) => {
    updateCapture(id, data)
      .then((data) => router('/rendez-vous/captures/'))
      .catch((err) => console.log(err));
  };

  return (
    <div className='Demande-Page'>
      <div className='Demands-container'>
        <div className='Demande'>
          <h3>
            <Icon name='info' /> {moment(capture?.date).format('dddd DD MMMM ')}{' '}
            à {capture?.time} (GMT+)
          </h3>
          <p>
            Demande de visite virtuelle<span>-{capture?.name}</span>
          </p>
          <div className='body'>
            <img src={capture?.images[0]} alt='' />
            <div className='right'>
              <section>
                <h5>Détails de la propriété</h5>

                <h6 className=''>Adresse: </h6>
                <p>{capture?.address}</p>
                <h6>Détails de la numérisation:</h6>
                <p>{capture?.numerisation}</p>
                <h6>Type de propriété:</h6>
                <p> Maison/Appartement</p>
                <h6>Détails de la propriété:</h6>
                <p>1 espace totalisant {capture?.superficie} m²</p>
                <h6>Nom de l'espace à numériser: </h6>
                <p>{capture?.ville}</p>
                <div className='flex'>
                  <h6>Services de Capture</h6>
                  <h4>
                    {capture?.superficie * 25} <span>MAD</span>
                  </h4>
                </div>
              </section>
              <section>
                <h5>Rendez vous</h5>
                <br />
                <p>
                  {moment(capture?.date).format('dddd DD MMMM ')} à{' '}
                  {capture?.time} (GMT+)
                </p>
              </section>
              <section>
                <h5>Contact</h5>
                <br />
                <p>
                  {capture?.name}
                  <br />
                  {capture?.email}
                  <br />
                  {capture?.phone}
                </p>
                <h6>
                  La personne de contact sera sur place:{' '}
                  {capture?.accuellir_le_technicien}
                </h6>
                <br />
              </section>
              <div className='buttons'>
                <button
                  className='add white'
                  onClick={() => UpdateCapture({ annuler: true })}
                >
                  Annuler
                </button>
                <button
                  onClick={() => UpdateCapture({ accepted: true })}
                  className='add'
                >
                  Accepter
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DemandeCapture;
