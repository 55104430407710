import { Outlet } from 'react-router-dom';
import AppLayout from '../AppLayout';
import CaptureService from '../../screens/CaptureService/CaptureService';
import Réserver_Solutions_de_Capture_Numérique from '../../screens/CaptureService/Réserver_Solutions_de_Capture_Numérique/Réserver_Solutions_de_Capture_Numérique';

const capture_serviceRouter = {
  path: '/capture_service',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    { path: '', element: <CaptureService /> },
    {
      path: 'réserver_solutions_de_capture_numérique',
      element: <Réserver_Solutions_de_Capture_Numérique />,
    },
  ],
};

export { capture_serviceRouter };
