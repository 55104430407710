import { Axios } from './axios';

const getDashboard = () => {
  return Axios.get(`/dashboard`).then(({ data }) => {
    console.log({ dashboard: data });
    return data;
  });
};

export { getDashboard };
