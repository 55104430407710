import { Axios } from './axios';

const getProperties = () => {
  return Axios.get(`/properties`).then(({ data }) => {
    console.log({ Properties: data?.properties });
    return data?.properties;
  });
};

const getPropertaires = () => {
  return Axios.get(`/owners`).then(({ data }) => {
    console.log({ Propertaires: data?.owners });
    return data?.owners;
  });
};

const getProperty = (id) => {
  return Axios.get(`/property/${id}`).then(({ data }) => {
    console.log({ Property: data?.property });
    return data?.property;
  });
};

const addProperty = (data) => {
  return Axios.post(`/property`, data).then(({ data }) => {
    return data;
  });
};

const updateProperty = (data, id) => {
  return Axios.put(`/property/${id}`, data).then(({ data }) => {
    return data;
  });
};

const deleteProperty = (id) => {
  return Axios.delete(`/property/${id}`).then(({ data }) => {
    return data;
  });
};

export {
  getProperties,
  addProperty,
  getProperty,
  updateProperty,
  deleteProperty,
  getPropertaires,
};
