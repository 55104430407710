import React, { Suspense, lazy } from 'react';
const PdfCreator = lazy(() => import('../../PdfCreator/PdfCreator'));
import Facture from '../../../components/Docs/Facture';
import FactureForm from '../../../components/Docs/Forms/FactureForm';

function FacturePage() {
  return (
    <Suspense fallback={<>Loading...</>}>
      <PdfCreator isFacture={true} Element={Facture} Form={FactureForm} />
    </Suspense>
  );
}

export default FacturePage;
