import { Outlet } from 'react-router-dom';
import CaptureServiceRV from '../../screens/RendezVous/Gestion/CaptureServiceRD';
import Demande from '../../screens/RendezVous/Demande';
import Demandes from '../../screens/RendezVous/Demandes';
import VisitesVirtuellesRD from '../../screens/RendezVous/Gestion/VisitesVirtuellesRD';
import RendezVous from '../../screens/RendezVous/RendezVous';
import AppLayout from '../AppLayout';
import DemandeCapture from '../../screens/RendezVous/DemandeCapture';

const RendezVousRouter = {
  path: '/rendez-vous',
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),
  children: [
    { path: '', element: <RendezVous /> },
    { path: 'capture_service', element: <CaptureServiceRV /> },
    { path: 'visites_virtuelles', element: <VisitesVirtuellesRD /> },
    { path: 'demandes', element: <Demandes type='visite virtuelle' /> },
    { path: 'demandes/:id', element: <Demande /> },
    {
      path: 'captures',
      element: <Demandes title='' link='captures' />,
    },
    { path: 'captures/:id', element: <DemandeCapture /> },
  ],
};

export { RendezVousRouter };
