import { Outlet } from 'react-router-dom';
import AppLayout from '../AppLayout';
import { lazy } from 'react';
const SuperVis = lazy(() => import('../../screens/SuperVis/SuperVis'));

const callRouter = {
  path: '/call',
  element: (
      <Outlet />
  ),
  children: [
    {
      path: ':id',
      element: <SuperVis />,
    },
  ],
};

export { callRouter };
