import React from 'react';
import FormPropriete from './FormPropriete/FormPropriete';
import { addProperty } from '../../utils/api/properties';
import { useNavigate } from 'react-router-dom';
import { getOwners } from '../../utils/api/users';
import { useQuery } from 'react-query';

function AddProperiete() {
  const router = useNavigate();
  const { data: owners, error } = useQuery('owners', getOwners);

  const SaveProprety = (data) => {
    addProperty(data)
      ?.then((p) => {
        router('/proprietes');
      })
      .catch((err) => {});
  };

  if (owners) return <FormPropriete {...{ SaveProprety, owners }} />;
}

export default AddProperiete;
