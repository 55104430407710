import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import Icon from '../Icon/Icon';

function TrakingSteps({ Step = 1, SwiperRef }) {
  const steps = [
    'Fiche d’intervention envoyé',
    'Fiche d’intervention Accepté',
    'Visite initiale',
    'Début d’intervention',
    'Confirmation et signatures',
  ];
  return (
    <div className='steps'>
      <p>
        Fiche d’intervention 1872 est envoyé le 17/04/2024 est actuellement en
        cours de traitement
      </p>
      <div className='steps-container'>
        <Swiper slidesPerView={'auto'} ref={SwiperRef}>
          {steps.map((step, i) => (
            <SwiperSlide
              key={i}
              className={`step ${Step >= i + 1 ? 'active' : ''} `}
            >
              <Icon name='success' />
              <p> {step}</p>
              {i + 1 > 1 && (
                <div className='line'>
                  <div></div>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <p>
        Date de fin prévue : <span className='bold'>18/04/2024</span>
      </p>
    </div>
  );
}

export default TrakingSteps;
