import FlexLinks from "../../components/FlexLinks/FlexLinks";
import "./EtatDesLieux.css";

function EtatDesLieux() {
  return (
    <div className="EtatDesLieux">
      <FlexLinks>
        {({ save_items }) => (
          <div className="Objects-container save">
            <div className="Objects">
              <button
                className=" bg-blue-500 text-white py-2 px-4 w-1/2 rounded-lg"
                onClick={() => save_items()}
              >
                Enregistrer
              </button>
            </div>
          </div>
        )}
      </FlexLinks>
    </div>
  );
}

export default EtatDesLieux;
