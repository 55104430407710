import React from 'react';
import Icon from '../Icon/Icon';
import { Link } from 'react-router-dom';
import './TrackingRight.css';
import { useQuery } from 'react-query';

function TrackingRight({
  button = 'Télécharger la fiche d’intervention',
  click = () => {},
  value = {},
}) {
  const { data: profile } = useQuery('profile');

  return (
    <div className='right-info'>
      <div className='flex'>
        <div>
          <p className='bold'>Fiche d’intervention N°{value?.documentId}</p>
          <p>Ordre N°{value?.documentId}</p>
        </div>
        {profile?.role !== 'Technicien' && value?.step > 4 && (
          <Link to='/paiements'>Payer Maintenant</Link>
        )}
      </div>

      <div>
        <p className='bold'>Unité D’intervention</p>
        <p>{value?.unité?.unité}</p>
      </div>
      <div className='flex'>
        <Icon name='user' />
        <div>
          <p className='bold'>Emetteur</p>
          <p>
            {value?.user?.first_name} {value?.user?.last_name}
          </p>
        </div>
      </div>
      <div className='flex '>
        <Icon name='technicien' />
        <div>
          <p className='bold'>Technicien</p>
          <p>
            {' '}
            {value?.technicien?.first_name} {value?.technicien?.last_name}
          </p>
        </div>
      </div>
      <div className='flex'>
        <Icon name='home-check' />
        <div>
          <p className='bold'>Adresse</p>
          <p>{value?.unité?.address}</p>
        </div>
      </div>

      {/* <div className='flex'>
        <Icon name='settings' />
        <div>
          <p className='bold'>Adresse</p>
          <p>
            Unité 1243 Derb sidi Kaouki Atlas menare Résidence Maarif,
            Casablance, MA
          </p>
        </div>
      </div> */}

      <div className='flex'>
        <Icon name='past' />
        <div>
          <p className='bold'>Détails de contact</p>
          <p>
            {' '}
            {value?.user?.first_name} {value?.user?.last_name} :{' '}
            {value?.user?.email} <br />
            {value?.technicien?.first_name} {value?.technicien?.last_name} :{' '}
            {value?.technicien?.email}
          </p>
        </div>
      </div>

      <div>
        <p className='bold'>Détails de D’intervention</p>
        <p>{value?.description}</p>
      </div>

      <div className='right-action'>
        {button && (
          <button className='add' onClick={click}>
            {button}
          </button>
        )}
      </div>
    </div>
  );
}

export default TrackingRight;
