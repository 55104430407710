import React from 'react';

function TextArea({
  register = () => {},
  errors = {},
  lable = '',
  name = '',
  attr = {},
}) {
  return (
    <div className='input-container full'>
      <label className='bold' htmlFor=''>
        {lable}
      </label>
      <textarea
        className={`${errors && errors[name] ? 'error' : ''}`}
        {...register(name)}
        rows='5'
        placeholder={lable}
        {...attr}
      ></textarea>
      <span className='error'>
        {`${errors && errors[name]?.message ? errors[name]?.message : ''}`}
      </span>
    </div>
  );
}

export default TextArea;
